// Core react imports
import * as React from 'react';

// Mui Imports
import * as Mui from './wrapmui';

// Public utilities
import classNames = require('classnames');

// Shared libraries
import { Util } from '@dra2020/baseclient';

// App libraries
import * as ClientActions from "../clientactions";
import * as MA from './materialapp';

export interface AboutMenuProps
{
  actions: ClientActions.IClientActions,
  elOn: any,

  classes?: any,
  theme?: any,
}

export interface AboutMenuState
{
  elOn: any,
}

function AboutMenuStyles(theme: any): any
{
  return (MA.AppStyles(theme));
}

class InternalAboutMenu extends React.Component<AboutMenuProps, AboutMenuState>
{
  constructor(props: any)
  {
    super(props);
    this.state = { elOn: props.elOn };
  }

  handleClose(): void
  {
    const {actions} = this.props;

    actions.fire(ClientActions.AboutMenu);
    this.setState({ elOn: null });
  }

  render(): any
  {
    const {classes, actions} = this.props;
    const {env} = actions;
    const {elOn} = this.state;

    let u = env.ss.user;
    let isAnon = env.ss.isAnon;

    let act = (a: number, arg?: any) => { this.handleClose(); actions.fire(a, arg) }

    return (
      <Mui.Menu
        open={true}
        variant={'menu'}
        onClose={() => this.handleClose()}
        anchorEl={elOn}
        anchorOrigin={{vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{vertical: 'top', horizontal: 'center' }}
      >
       <Mui.MenuItem onClick={() => { act(ClientActions.Home) }}>
         <span className={classes.menuText}>Home</span>
       </Mui.MenuItem>
       <Mui.MenuItem onClick={() => { act(ClientActions.About) }}>
         <span className={classes.menuText}>About</span>
       </Mui.MenuItem>
       <Mui.MenuItem onClick={() => { act(ClientActions.Help) }}>
         <span className={classes.menuText}>Help</span>
       </Mui.MenuItem>
       <Mui.MenuItem onClick={() => { act(ClientActions.Privacy) }}>
         <span className={classes.menuText}>Privacy</span>
       </Mui.MenuItem>
      </Mui.Menu>
      );

  }
}

let StyledAboutMenu: any = Mui.withStyles(AboutMenuStyles, {withTheme: true})(InternalAboutMenu);
export const AboutMenu: new () => React.Component<AboutMenuProps, {}> = StyledAboutMenu;
