// Public libraries
import * as $ from "jquery";
import * as React from "react";
import * as ReactDOM from "react-dom";

// OT
import { OT, Util, FSM } from "@dra2020/baseclient";

// App
import { Environment, Fsm } from "./env";
import * as ClientActions from "./clientactions";
import * as Ajax from "./ajax";
import * as MA from "./components/materialapp";

class FsmAccountAPI extends Ajax.FsmAjax
{
  constructor(env: Environment, url: string, data: any)
  {
    super(env, { url: url, data: JSON.stringify(data) });
  }
}

class FsmLogin extends Fsm
{
  actions: AccountActions;
  fsmLoginAPI: FsmAccountAPI;

  constructor(env: Environment, actions: AccountActions, data: any)
  {
    super(env);
    this.actions = actions;
    this.fsmLoginAPI = new FsmAccountAPI(env, '/login', data);
    this.waitOn(this.fsmLoginAPI);
  }

  tick(): void
  {
    if (this.ready && this.isDependentError)
    {
      this.actions.upfire(ClientActions.SetLoginMessage, 'Invalid username or password.');
      this.setState(FSM.FSM_ERROR);
    }
    else if (this.ready)
    {
      let data = this.fsmLoginAPI.data;
      if (data && data.result && data.message)
        this.actions.upfire(ClientActions.SetLoginMessage, data.message);
      else if (data.message)
        this.actions.upfire(ClientActions.Alert, { message: data.message });
      this.setState(FSM.FSM_DONE);
    }
  }
}

export class FsmAccount extends Fsm
{
  actions: AccountActions;
  fsmAccountAPI: FsmAccountAPI;

  constructor(env: Environment, actions: AccountActions, url: string, data: any)
  {
    super(env);
    this.actions = actions;
    this.fsmAccountAPI = new FsmAccountAPI(env, url, data);
    this.waitOn(this.fsmAccountAPI);
  }

  tick(): void
  {
    if (this.ready && this.isDependentError)
    {
      this.actions.upfire(ClientActions.Alert, { message: 'Server request failed.' });
      this.setState(FSM.FSM_ERROR);
    }
    else if (this.ready)
    {
      let data = this.fsmAccountAPI.data;
      if (data && data.message)
        this.actions.upfire(ClientActions.Alert, { message: data.message });
      this.setState(FSM.FSM_DONE);
    }
  }
}

export class AccountActions extends ClientActions.ClientActions
{
  message: string;
  requests: { [action: string]: Fsm };

  constructor(env: Environment)
  {
    super(env);
    this.message = '';
    this.requests = {};
  }

  fire(id: number, arg?: any): boolean
  {
    let handled = true;

    switch (id)
    {
      default:
        handled = false;
        break;

      case ClientActions.Logout:
        window.location.replace('/logout');
        this.env.ss.logout();
        break;

      case ClientActions.Login:
        this.requests['login'] = new FsmLogin(this.env, this, { username: arg.email, password: arg.password });
        break;

      case ClientActions.Signup:
        this.requests['login'] = new FsmLogin(this.env, this, { signup: true, username: arg.email, password: arg.password });
        break;

      case ClientActions.ForgotPassword:
        this.requests['forgot'] = new FsmAccount(this.env, this, '/forgotpassword/fetch', arg);
        break;

      case ClientActions.ResetPassword:
        this.requests['reset'] = new FsmAccount(this.env, this, '/forgotpassword/set', arg);
        break;

      case ClientActions.VerifyEmail:
        this.requests['profile'] = new FsmAccount(this.env, this, '/api/sessions/profile', { verify: true });
        this.env.verifying = true;
        break;

      case ClientActions.Feedback:
        this.requests['profile'] = new FsmAccount(this.env, this, '/api/sessions/profile', { feedback: arg as string });
        break;

      case ClientActions.Profile:
        let param: any = {};
        if (arg.id)
        {
          param.id = arg.id;
          if (arg.name) param.name = arg.name;
          if (arg.email) param.email = arg.email;
          if (arg.password) param.password = arg.password;
          if (arg.twitterhandle) param.twitterhandle = arg.twitterhandle;
          if (arg.categories) param.categories = arg.categories;
          this.requests['profile'] = new FsmAccount(this.env, this, '/api/sessions/profile', param);
        }
        break;
    }

    return handled;
  }
}
