export interface SimpleDate
{
  day: number,        // 1-31
  month: number,      // 0-11
  year: number,       // yyyy
  hour: number,       // 0-23
  minute: number,     // 0-59
  timezone?: string,
  dayofweek?: number, // 0-6 (Sun-Sat)
}

export const TZList = ['Pacific', 'Mountain', 'Central', 'Eastern'];

export type DateString = string;  // [1-31].[0-11].[yyyy] [0-23]:[0-59] [tz]

export function formatDate(d: SimpleDate): DateString
{
  return `${d.day}.${d.month}.${d.year} ${d.hour}:${d.minute} ${d.timezone || ''}`
}

export function parseDate(s: DateString): SimpleDate
{
  let re = /^([^\.]+).([^\.]+).([^\.]+) ([^:]+):([^ ]+) (.*)$/;

  let d = new Date();
  let a = re.exec(s);
  if (!a || a.length < 6)
  {
    return {
      day: d.getDate(),
      month: d.getMonth(),
      year: d.getFullYear(),
      hour: d.getHours(),
      minute: d.getMinutes(),
      dayofweek: d.getDay(),
      };
  }
  else
  {
    // To compute dayofweek
    d = new Date(Number(a[3]), Number(a[2]), Number(a[1]));

    return {
      day: Number(a[1]),
      month: Number(a[2]),
      year: Number(a[3]),
      hour: Number(a[4]),
      minute: Number(a[5]),
      timezone: a[6],
      dayofweek: d.getDay(),
      };
  }
}

export function toDate(sd: SimpleDate): Date
{
  return new Date(sd.year, sd.month, sd.day, sd.hour, sd.minute);
}

export function fromDate(d: Date): SimpleDate
{
  return {
      day: d.getDate(),
      month: d.getMonth(),
      year: d.getFullYear(),
      hour: d.getHours(),
      minute: d.getMinutes(),
      dayofweek: d.getDay(),
    }
}

export function toMs(s: DateString): number
{
  let sd = parseDate(s);
  return toDate(sd).getTime();
}

export function fromMs(ms: number): DateString
{
  let d = new Date();
  d.setTime(ms);
  return formatDate(fromDate(d));
}

export function nowString(): DateString
{
  let d = new Date();
  return formatDate(fromDate(d));
}
