// Core react imports
import * as React from 'react';
import { useSwipeable } from 'react-swipeable';

// Mui Imports
import * as Mui from './wrapmui';
import * as Icons from './wrapicons';

// Public utilities
import classNames = require('classnames');

// Core OT
import { OT, Util } from "@dra2020/baseclient";

// App libraries
import { Environment } from '../env';
import * as ClientActions from '../clientactions';
import * as Hash from '../hash';
import * as DU from '../dateutil';
import * as SS from '../serverstate';
import { EventFilter } from '../eventfilter';
import * as SD from '../../shared/simpledate';
import * as CT from '../../shared/coretypes';
//import * as TV from './tableview';

import { Viewer } from './viewers';
import { ProfileMenu } from './profilemenu';
import { AboutMenu } from './aboutmenu';
import { EventView } from './eventview';
import { DayView } from './dayview';
import { CategoryView } from './categoryview';
import { ForgotView } from './forgotview';
import { ResetView } from './resetview';
import { LoginView } from './loginview';

let coder: Util.Coder = { encoder: new TextEncoder(), decoder: new TextDecoder('utf-8') };

export enum DW      // Enum representing size ranges for Available Width
{
  PHONE,
  PHONEPLUS,
  NARROW,
  NARROWPLUS,
  NARROWPLUS2,
  TABLET,
  MEDIUM,
  MEDIUMPLUS,
  WIDE,
  WIDER,
  WIDEST,
}

const fontSizeMicro = '.50rem';
const fontSizeTiny = '.70rem';
const fontSizeSmall = '.85rem';
const fontSizeMedium = '1rem';
const fontSizeLarge = '1.25rem';

const iconColor="#7d6ee7";

export class AppActions extends ClientActions.ClientActions
{
  app: ClientActions.IClientActions;

  constructor(app: ClientActions.IClientActions)
  {
    super(app.env);
    this.app = app;
  }

  fireIcon(cmd: ClientActions.ParamTableIcon): void
  {
    switch (cmd.name)
    {
    }
  }

  fire(id: number, arg?: any): boolean
  {
    switch (id)
    {
      case ClientActions.TableIconSelect:
        this.fireIcon(arg as ClientActions.ParamTableIcon);
        break;

      // Local actions
      case ClientActions.TextChange:
      case SetCommentText:
        return this.app.fire(id, arg);

      default:
        return this._fire(id, arg);;
    }

    return true;
  }
}

export interface AppProps
{
  env: Environment,
  title: string,
  actions: ClientActions.ClientActions,

  // Dialogs
  progressState: ClientActions.ParamProgress,
  alertState: ClientActions.ParamAlert,
  popoverState?: ClientActions.ParamPopover,

  // Temporary menus
  elProfileMenuOn?: any,
  elAboutMenuOn?: any,
  elCardItemMenuOn?: any,
  elPopoverOn?: any,

  // Stuff to display
  selection: Util.CountedHash,

  viewState: ClientActions.ViewState,
  dateString: SD.DateString,
  eventEdit: CT.Event,
  readonly: boolean,
  today: boolean,
  categoryEdit: CT.Category,
  designSize: DW,
  designWidth: number,
  statView: ClientActions.StatLiteral,
  listView: ClientActions.ListLiteral,
  loginMessage?: string,
  resetGUID?: string,
  isTouch: boolean,
  eventFilter: EventFilter,

  clearState?: boolean,
  classes?: any,
  theme?: any,
}

export interface AppState
{
  filterString?: string,
  filterEl?: any,
  eventViewFilter: EventFilter,
  textInit: ClientActions.TextInit,
}

const shadingColor = Mui.indigo[50];

export const sxToggle = {
  '&.Mui-selected': {
    backgroundColor: '#cccccc',
  // color: 'primary.contrastText',
    '&:hover': {
      backgroundColor: '#bbbbbb',
      },
    },
  paddingTop: '2px',
  paddingBottom: '2px',
  paddingLeft: '3px',
  paddingRight: '3px',
};

export function AppStyles(theme: any): any
{
  return ({
    root: {
      flexGrow: 1,
      overscrollBehavior: 'none',
    },
    mainCanvasWrapper: {
      position: 'absolute',
      top: '36px',
      left: '4px',
      width: 'calc(100% - 8px)',
      height: 'calc(100vh - 36px)',
      overscrollBehavior: 'none',
    },
    title: {
      fontSize: fontSizeMedium,
      fontFamily: theme.typography.fontFamily,
      whiteSpace: 'nowrap',
      cursor: 'pointer',
      paddingLeft: 10,
    },
    mainCanvasInner: {
      position: 'absolute',
      top: '71px',
      height: 'calc(100vh - 103px)',
      paddingLeft: '4px',
      paddingRight: '4px',
      maxWidth: '1400px',
      width: '100%',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    readonlyToggle: {
      paddingLeft: '4px',
      paddingRight: '4px',
      maxWidth: '640px',
      width: '100%',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    mainDialogInner: {
      paddingBottom: '0px',
      paddingLeft: '4px',
      paddingRight: '4px',
      maxWidth: '320px',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    mainCanvas: {
      backgroundColor: 'white',
      width: '100%',
      height: '100%',
      overscrollBehavior: 'none',
    },
    viewTitle: {
      textAlign: 'center',
      fontSize: fontSizeLarge,
      fontFamily: theme.typography.fontFamily,
    },
    viewPort: {
      overflowX: 'auto',
      overflowY: 'scroll',
      height: 'calc(100vh - 145px)',
    },
    viewCanvas: {
      paddingTop: '10px',
      maxWidth: '640px',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    popoverViewPort: {
      overflowX: 'auto',
      overflowY: 'scroll',
      height: '200px',
    },
    categoryColumn: {
      width: '80px',
      paddingRight: '4px',
      paddingLeft: '4px',
      paddingTop: '4px',
      paddingBottom: '0px',
      verticalAlign: 'middle',
      fontWeight: 'bold',
    },
    eventColumn: {
      verticalAlign: 'middle',
      paddingTop: '4px',
      paddingBottom: '0px',
    },
    categoryRow: {
      paddingTop: '4px',
    },
    menuAbout: {
      marginLeft: 4,
      marginRight: 4,
    },
    fontBody: {
      fontSize: fontSizeMedium,
      fontFamily: theme.typography.fontFamily,
    },
    fontLarge: {
      fontSize: fontSizeLarge,
      fontFamily: theme.typography.fontFamily,
    },
    fontSmall: {
      fontSize: `${fontSizeSmall} !important`,
      fontFamily: theme.typography.fontFamily,
    },
    fontTiny: {
      fontSize: fontSizeTiny,
      fontFamily: theme.typography.fontFamily,
    },
    fontMicro: {
      fontSize: fontSizeMicro,
      fontFamily: theme.typography.fontFamily,
    },
    buttonRow: {
      marginTop: '10px',
      marginBottom: '10px',
      whiteSpace: 'nowrap',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
      width: '100%',
    },
    marginBox: {
      margin: '10px',
    },
    bold: {
      fontWeight: 'bold',
    },
    center: {
      textAlign: 'center',
    },
    centerBlock: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'flex-start',
    },
    right: {
      textAlign: 'right',
    },
    growAble: {
      flexGrow: 1,
    },
    mainTextField: {
      flexGrow: 1,
    },
    daySnip: {
      width: '15px',
      height: '15px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginBottom: '1px',
      marginRight: '1px',
      marginLeft: '1px',
      fontSize: fontSizeTiny,
      color: 'white',
    },
    dayYearSnip: {
      width: '5px',
      height: '5px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginBottom: '1px',
      marginRight: '1px',
      marginLeft: '1px',
      fontSize: fontSizeMicro,
      color: 'white',
    },
    tileNarrow: {
      width: '50px',
      height: '50px',
    },
    tileWide: {
      width: '80px',
      height: '80px',
    },
    tileYear: {
      width: '25px',
      height: '25px',
    },
    dayText: {
      position: 'absolute',
      top: '4px',
      left: '4px',
      color: '#aaaaaa',
      fontSize: fontSizeMedium,
      fontFamily: theme.typography.fontFamily,
    },
    dayYearText: {
      position: 'absolute',
      top: '2px',
      left: '4px',
      color: '#aaaaaa',
      fontSize: fontSizeMedium,
      fontFamily: theme.typography.fontFamily,
    },
    dayTile: {
      position: 'relative',
      borderStyle: 'solid',
      borderWidth: 1,
      borderColor: 'grey',
      boxSizing: 'border-box',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'start',
      justifyContent: 'center',
      alignContent: 'center',
      flexWrap: 'wrap',
      marginRight: '1px',
      marginBottom: '1px',
      overflow: 'hidden',
    },
    emptyTile: {
      borderStyle: 'solid',
      borderWidth: 1,
      borderColor: 'grey',
      boxSizing: 'border-box',
      marginRight: '1px',
      marginBottom: '1px',
    },
    headerTile: {
      backgroundColor: '#dfdfdf',
      boxSizing: 'border-box',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      marginRight: '1px',
      marginBottom: '1px',
      fontSize: fontSizeMedium,
      fontFamily: theme.typography.fontFamily,
    },
    mainTextDisplay: {
      fontSize: fontSizeMedium,
      fontFamily: theme.typography.fontFamily,
      flexGrow: 1,
    },
    staticTextTitle: {
      fontSize: fontSizeMedium,
      fontFamily: theme.typography.fontFamily,
      fontWeight: 'bold',
      flexGrow: 1,
      marginBottom: '.5rem',
    },
    staticTextDisplay: {
      fontSize: fontSizeSmall,
      fontFamily: theme.typography.fontFamily,
      flexGrow: 1,
      marginBottom: '.5rem',
    },
    anonTextDisplay: {
      fontSize: fontSizeMedium,
      fontFamily: theme.typography.fontFamily,
      flexGrow: 1,
      textAlign: 'center',
      marginTop: 15,
      marginBottom: 15,
    },
    smallTextDisplay: {
      fontSize: fontSizeSmall,
      fontFamily: theme.typography.fontFamily,
      marginBottom: 4,
    },
    searchControl: {
      minWidth: 120,
      marginLeft: 2,
    },
    searchInput: {
    },
    spacer: {
      flex: '1 1 100%',
    },
    fillSpace: {
      width: '100%',
    },
    fillSpread: {
      width: '100%',
      justifyContent: 'space-between',
    },
    actions: {
      color: theme.palette.text.secondary,
    },
    secondary: {
      color: theme.palette.secondary.main,
    },
    primary: {
      color: theme.palette.primary.main,
    },
    appBarColorPrimary: {
      backgroundColor: theme.palette.primary.main,
    },
    appFrame: {
      zIndex: 1,
      overflow: 'hidden',
      position: 'relative',
      display: 'flex',
      width: '100%',
      height: 'calc(100vh)',
      overscrollBehavior: 'none',
    },
    appBarButton: {
      marginRight: '10px !important',
      color: 'white !important',
      padding: '2px !important',
      fontSize: `${fontSizeMedium} !important`,
      fontFamily: theme.typography.fontFamily,
    },
    iconParent: {
      position: 'relative',
    },
    iconPick: {
      position: 'absolute',
      top: 0,
      right: 0,
      color: 'seagreen',
      width: '16px',
      height: '16px',
      fontSize: fontSizeSmall,
    },
    statH1: {
      fontSize: fontSizeLarge,
      fontFamily: theme.typography.fontFamily,
      fontWeight: 'bold',
    },
    statH2: {
      fontSize: fontSizeMedium,
      fontFamily: theme.typography.fontFamily,
      fontWeight: 'bold',
      marginLeft: '4px',
    },
    statH3: {
      fontSize: fontSizeMedium,
      fontFamily: theme.typography.fontFamily,
      fontWeight: 'bold',
      marginLeft: '8px',
    },
    statH4: {
      fontSize: fontSizeMedium,
      fontFamily: theme.typography.fontFamily,
      marginLeft: '12px',
    },
    colorEven: {
      backgroundColor: '#f8f8f8',
    },
    colorOdd: {
      backgroundColor: '#ffffff',
    },
    verticalMiddle: {
      verticalAlign: 'middle',
    },
    viewToggle: {
      marginRight: '4px',
    },
    subviewToggle: {
      marginRight: '4px',
      marginTop: '5px',
      padding: '2px',
    },
    eventButton: {
      marginRight: 4,
      marginBottom: 4,
    },
    popoverOuter: {
      padding: 2,
      backgroundColor: 'grey',
    },
    popoverInner: {
      color: 'black',
      backgroundColor: 'white',
      fontSize: fontSizeSmall,
      fontFamily: theme.typography.fontFamily,
      padding: 4,
    },
    appBar: {
      width: '100%',
      height: '32px !important',
      minHeight: '32px !important',
    },
    toolBar: {
      height: '32px !important',
      minHeight: '32px !important',
    },
    menuButton: {
      marginLeft: 2,
      marginRight: 2,
    },
    menuText: {
      fontSize: fontSizeMedium,
      fontFamily: theme.typography.fontFamily,
    },
    hide: {
      display: 'none',
    },
    bigDialogPaper: {
      maxWidth: '80vw',
      minWidth: '80vw',
    },
    dialogRoot: {
      minWidth: 552,
    },
    tableTitle: {
      marginLeft: 2,
    },
    simpleRow: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
      width: '100%',
    },
    wrapRow: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      flexWrap: 'wrap',
    },
    simpleWrapRow: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
      flexWrap: 'wrap',
    },
    filledRow: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'stretch',
      justifyContent: 'flex-start',
      width: '100%',
    },
    spreadRow: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      alignContent: 'stretch',
      justifyContent: 'center',
      width: '100%',
    },
    simpleColumn: {
      display: 'flex',
      flexDirection: 'column',
      flexShrink: 0,  // Fix Safari bug where scrolling content gets shrunk instead of scrolling
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
      width: '100%',
    },
    firstColumn: {
      width: 120,
      paddingRight: 12,
      textAlign: 'right',
    },
    table: {
      position: 'relative',
      width: '100%',
      height: '90vh',
    },
    tableHeader: {
      display: 'flex',
      alignItems: 'left',
      justifyContent: 'flex-start',
      padding: 0,
      ...theme.mixins.toolbar,
    },
    tableHeadColor: {
      backgroundColor: '#d5dbdb',
    },
    tableAlternatingShading: {
      backgroundColor: '#ebdef0',
    },
    themeBackgroundColor: {
      backgroundColor: theme.palette.background.default,
    },
    sessionTable: {
      minWidth: 520,
    },
    sessionTableWrapper: {
      overflowX: 'auto',
      overflowY: 'scroll',
      width: '100%',
      height: 'calc(100vh - 136px)',
    },
    noMargin: {
      marginLeft: 0,
      marginRight: 0,
      padding: 0,
      borderWidth: 0,
    },
    noPadding: {
      padding: 0,
    },
    denseIcon: {
      width: 24,
      height: 24,
    },
    iconLarge: {
      fontSize: 36,
    },
    veryDenseIcon: {
      width: '16px',
      height: '16px',
      fontSize: '16px',
    },
    denseLabel: {
      fontSize: '0.7rem',
      marginLeft: 2,
      marginRight: 2,
    },
    smallLabel: {
      fontSize: '0.6rem',
      marginLeft: 0,
      marginRight: 0,
    },
    denseFormControl: {
      marginLeft: 2,
      marginRight: 2,
    },
    padding4: {
      padding: 4,
    },
    shortControl: {
      minWidth: 120,
      fontSize: fontSizeMedium,
    },
    denseInput: {
      fontSize: fontSizeMedium,
      width: 120,
    },
    denseMenuItem: {
      fontSize: fontSizeSmall,
      marginLeft: 0,
      marginRight: 0,
      height: 24,
    },
    denseSelect: {
      fontSize: fontSizeSmall,
      marginLeft: 0,
      marginRight: 0,
      minWidth: 48,
    },
    denseIconWithLabel: {
      width: 'fit-content',
      minWidth: '60px',
    },
    denseLabel1: {
      fontSize: fontSizeSmall,
      marginLeft: 0,
      marginRight: 0,
    },
    denseLabel2: {
      fontSize: fontSizeSmall,
      margin: 1,
      padding: 1,
    },
    denseLabel3: {
      fontSize: fontSizeSmall,
      margin: '1px 0px 1px 1px',      // no right margin/padding, because chevron there
      padding: '1px 0px 1px 1px',
    },
    checkCell: {
      fontSize: fontSizeSmall,
      padding: 0,
      margin: 0,
      width: 0,
    },
    denseCell: {
      fontSize: fontSizeSmall,
      padding: 0,
      margin: 0,
      '&:last-child': {
        paddingRight: 1,
      },
    },
    subheading: {
      marginTop: 6,
      marginBottom: 3,
    },
    smallPadding: {
      padding: 2,
      margin: 2,
    },
    smallIcon: {
      fontSize: 'small',
      padding: 2,
    },
    linkText: {
      '&:hover': {
        textDecoration: 'underline',
        cursor: 'pointer',
      },
    },
    popupMenuItem: {
      '&:hover': {
        backgroundColor: '#f0f0f0',
      },
      padding: 4,
      cursor: 'pointer',
    },
    popupMenu: {
      position: 'absolute',
      top: '105%',
      borderStyle: 'solid',
      borderColor: 'black',
      borderWidth: 1,
    },
    alertText: {
      color: 'red',
    },
    blueLinkText: {
      color: theme.palette.secondary.main,
      fontSize: fontSizeMedium,
      fontWeight: 500,
      padding: 8,
      fontFamily: theme.typography.fontFamily,
      '&:hover': {
        textDecoration: 'underline',
        cursor: 'pointer',
      },
    },
    dialogScrollBlock: {
      height: 400,
      overflowY: 'scroll',
      fontFamily: theme.typography.fontFamily,
    },
    commentName: {
      fontSize: fontSizeSmall,
      color: 'black',
      fontWeight: 'bold',
    },
    separator: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
      height: 36,
      width: '100%',
      maxWidth: 552,
    },
    font875: {
      fontSize: fontSizeSmall,
    },
    font1rem: {
      fontSize: fontSizeMedium,
    },
    loginButton: {
      fontSize: fontSizeMedium,
    },
    buttonText: {
      fontSize: fontSizeSmall,
      fontFamily: theme.typography.fontFamily,
    },
    cellNoBorder: {
      border: '0px',
    },
    cellTopBorder: {
      borderTopStyle: 'solid',
      borderTopColor: 'grey',
      borderTopWidth: 1,
      borderLeft: '0px',
      borderRight: '0px',
      borderBottom: '0px',
    },
    cellTight: {
      padding: '2px',
    },
  });
}

let UIActionID = 50000;
export let SetCommentText = UIActionID++;


type TouchHandler = (e: any) => void;

const SwipeableComponent = ({ root, onSwipedLeft, onSwipedRight }:
                            { root: any,
                              onSwipedLeft: TouchHandler, onSwipedRight: TouchHandler }) => {
  const handlers = useSwipeable({
    onSwipedLeft: onSwipedLeft,
    onSwipedRight: onSwipedRight,
  });

  return (
      <div {...handlers} style={{ width: '100%', height: '100vh' }}>
        {root}
      </div>
    );
}

class InternalMaterialApp extends React.Component<AppProps, AppState>
{
  env: Environment;
  appActions: AppActions;
  initials: any;

  constructor(props: AppProps)
  {
    super(props);

    this.env = props.actions.env;

    this.appActions = new AppActions(this);
    props.actions.mixin(this.appActions);

    this.handleMainToggle = this.handleMainToggle.bind(this);
    this.handleHome = this.handleHome.bind(this);
    this.handleFilterText = this.handleFilterText.bind(this);
    this.onSwipedLeft = this.onSwipedLeft.bind(this);
    this.onSwipedRight = this.onSwipedRight.bind(this);
    this.onSwipedUp = this.onSwipedUp.bind(this);
    this.onSwipedDown = this.onSwipedDown.bind(this);
    this.checkFilterAll = this.checkFilterAll.bind(this);
    this.initials = {};
    this.state = { filterString: '', filterEl: null, eventViewFilter: new EventFilter(this.env), textInit: { comment: '' } };
  }

  initialsOf(name: string): string
  {
    function initial(s: string): string
    {
      for (let i = 0; i < s.length; i++)
        if (/[a-zA-Z]/.test(s[i])) return s[i];
      return s.length > 0 ? s[0] : '';
    }

    if (this.initials[name]) return this.initials[name];
    let initials = initial(name);
    this.initials[name] = initials;
    return initials;
  }

  fire(id: number, e?: any): boolean
  {
    const { env, actions } = this.props;
    const u = env.ss.user;
    let param: any;

    switch (id)
    {
      case SetCommentText:
        this.setState({ textInit: { comment: e as string } });
        break;

      default:
        console.log('materialapp.fire: Unexpected app action');
        return false;
        break;
    }

    return true;
  }

  onSwipedLeft(e: any): void
  {
    this.props.actions.fire(ClientActions.SwipedLeft);
  }

  onSwipedRight(e: any): void
  {
    this.props.actions.fire(ClientActions.SwipedRight);
  }

  onSwipedUp(e: any): void
  {
    this.props.actions.fire(ClientActions.SwipedUp);
  }

  onSwipedDown(e: any): void
  {
    this.props.actions.fire(ClientActions.SwipedDown);
  }

  handleMainToggle(e: any): void
  {
    const {actions, eventFilter} = this.props;
    const fireMap: any = {
        day: ClientActions.Day,
        month: ClientActions.Month,
        year: ClientActions.Year,
        stats: ClientActions.Stats,
        events: ClientActions.Events,
        comments: ClientActions.Comments,
      };

    eventFilter.reset(true);
    actions.fire(fireMap[e.currentTarget.value]);
  }

  renderUnverified(): any
  {
    const {classes, actions} = this.props;

    if (! this.env.ss.isUnverified) return null;

    return (
        <div id={'unverifiedview'} className={classes.mainCanvasWrapper}>
          <div className={classes.mainCanvasInner}>
            <div className={classes.mainTextDisplay}>
              You should have verification email in your <b>{this.env.ss.user.email}</b> account (maybe the junk mail folder)!
              <br />
              <br />
              Please follow the link in that email and then return here to start using the app.
              The app should notice automatically but if you get impatient after verifying,
              just click the Check Verification Status button below.
              <br />
              <br />
              Or change your email address if the address above is wrong! Bonkers.
              <br />
              <br />
            </div>
            <div className={classes.center}>
              <Mui.Button variant='outlined' onClick={() => this.env.ss.refreshUser() }>
                Check Verification Status
              </Mui.Button>
              &nbsp;&nbsp;
              <Mui.Button variant='outlined' onClick={() => actions.fire(ClientActions.OpenProfile) }>
                Change Email
              </Mui.Button>
              &nbsp;&nbsp;
              <Mui.Button variant='outlined' onClick={() => { actions.fire(ClientActions.VerifyEmail); actions.fire(ClientActions.Alert, { message: 'Email sent.' }) } }>
                Resend Email
              </Mui.Button>
            </div>
          </div>
        </div>
    );
  }

  renderWelcome(): any
  {
    const {classes, actions, viewState, loginMessage} = this.props;

    if (this.env.ss.userSet) return null;

    return (
        <div id={'anonview'} className={classes.mainCanvasWrapper}>
          <div className={classes.mainCanvasInner}>
            <div className={classes.anonTextDisplay}>
              Welcome to What I Did! Initializing with server...
            </div>
          </div>
        </div>
    );
  }

  renderAnon(): any
  {
    const {classes, actions, viewState, loginMessage} = this.props;

    if (! this.env.ss.isAnon || ! this.env.ss.userSet) return null;
    let top = viewState.top;
    if (top === 'login' || top === 'signup' || top === 'forgot' || top === 'reset' ||
        top === 'help' || top === 'about' || top === 'privacy') return null;

    return (
        <div id={'anonview'} className={classes.mainCanvasWrapper}>
          <div className={classes.mainCanvasInner}>
            <div className={classes.anonTextDisplay}>
              Please log in or create an account!
            </div>
            <div className={classNames(classes.anonTextDisplay, classes.alertText)}>
              {loginMessage}
            </div>
            <div className={classes.center}>
              <Mui.Button variant='outlined' onClick={() => { actions.fire(ClientActions.OpenLogin) }}>
                Login
              </Mui.Button>
              &nbsp;
              &nbsp;
              <Mui.Button variant='outlined' onClick={() => { actions.fire(ClientActions.OpenSignup) }} color='primary'>
                Sign Up
              </Mui.Button>
            </div>
            <div className={classNames(classes.anonTextDisplay, classes.linkText)}
              onClick={() => { actions.fire(ClientActions.PushView, 'forgot')}}
             >
              Forgot password?
            </div>
          </div>
        </div>
    );
  }

  renderFilterButton(): any
  {
    const {classes, actions, viewState} = this.props;
    const {filterString} = this.state;

    if (this.env.ss.isAnon || this.env.ss.isUnverified)
      return null;

    let vs = viewState.top;
    if (vs !== 'year' && vs !== 'month') return null;

    return (
        <Mui.IconButton id='filterbutton' color='inherit' size={'small'} onClick={() => { this.showFilterButton() }}>
          <Icons.FilterAltOutlined fontSize={'small'} />
          <Mui.Typography className={classes.buttonText}>
            Filter Events
          </Mui.Typography>
        </Mui.IconButton>
      );
  }

  showFilterButton(): void
  {
    const {eventViewFilter} = this.state;

    eventViewFilter.reset(true);
    this.setState({ filterString: '', filterEl: document.getElementById('filterbutton'), eventViewFilter });
  }

  checkFilterAll(e: any): void
  {
    const {actions, eventFilter} = this.props;
    const {filterString, eventViewFilter} = this.state;

    let on = e.target.checked;
    if (filterString)
      eventViewFilter.forEach((eid: string) => { if (on) eventFilter.setEvent(eid); else eventFilter.clearEvent(eid) });
    else
      eventFilter.reset(on);
    actions.fire(ClientActions.Render);
  }

  renderFilterPopover(): any
  {
    const {classes, actions, eventFilter, viewState, dateString} = this.props;
    const {filterString, filterEl, eventViewFilter} = this.state;

    if (! filterEl) return null;

    let checkEvent = (eid: string) => {
        if (eventFilter.testEvent(eid))
          eventFilter.clearEvent(eid);
        else
          eventFilter.setEvent(eid);
        actions.fire(ClientActions.Render);
      };
    let checkCategory = (e: any, cid: string) => {
        let on = e.target.checked;
        eventViewFilter.forEach((eid: string) => {
            let e = this.env.ss.events[eid];
            if (e && e.idCat === cid)
            {
              if (on)
                eventFilter.setEvent(eid);
              else
                eventFilter.clearEvent(eid);
            }
          });
        actions.fire(ClientActions.Render);
      };

    let eventbox = (id: string) => {
        return (
            <Mui.Checkbox id={id} checked={eventFilter.testEvent(id)} indeterminate={false}
              style={{marginLeft: '20px', padding: 2}}
              onClick={() => { checkEvent(id) }}
              />
          )
      };
    let categoryIndeterminate = (id: string) => {
        if (! filterString)
          return eventFilter.testCategoryIndeterminate(id);
        else
        {
          let ind = false;
          let on: boolean;
          eventViewFilter.forEach((eid: string) => {
              let e = this.env.ss.events[eid];
              if (e && e.idCat === id)
                if (on === undefined)
                  on = eventFilter.testEvent(eid);
                else if (on !== eventFilter.testEvent(eid))
                  ind = true;
            });
          return ind;
        }
      };
    let categoryTest = (id: string) => {
        if (! filterString)
          return eventFilter.testCategory(id);
        else
        {
          let on = true;
          eventViewFilter.forEach((eid: string) => {
              let e = this.env.ss.events[eid];
              if (e && e.idCat === id)
              if (! eventFilter.testEvent(eid))
                on = false;
            });
          return on;
        }
      };
    let categorybox = (id: string) => {
        return (
            <Mui.Checkbox id={id} checked={categoryTest(id)}
              indeterminate={categoryIndeterminate(id)}
              style={{padding: 2}}
              onClick={(e: any) => { checkCategory(e, id) }}
              />
          )
      };

    let vs = viewState.top;
    let sd = SD.parseDate(dateString);
    let stats = this.env.ss.stats(String(sd.year), vs === 'month' ? String(sd.month) : undefined);
    let all = 0; eventViewFilter.forEach(eid => { all += stats.get(eid) || 0 });

    let boxes: any[] = [];
    // 'All' checkbox
    if (filterString)
    {
      let isall = eventFilter.isEqual(eventViewFilter);
      boxes.push(
          <div className={classes.simpleRow}>
            <Mui.Checkbox checked={isall} style={{padding: 2}}
             indeterminate={!isall && eventViewFilter.size > 0}
             onClick={this.checkFilterAll}
             />
            <Mui.Typography className={classes.buttonText}>{`(All Filtered Events) (${all} total)`}</Mui.Typography>
          </div>
        )
    }
    else
    {
      boxes.push(
          <div className={classes.simpleRow}>
            <Mui.Checkbox checked={eventFilter.isAll()} style={{padding: 2}}
             indeterminate={eventFilter.size && eventFilter.size != eventViewFilter.size}
             onClick={this.checkFilterAll}
             />
            <Mui.Typography className={classes.buttonText}>{`(All Events) (${all} total)`}</Mui.Typography>
          </div>
        )
    }
    this.env.ss.eventsByCategory.forEach((es: string[], cid: string) => {
        let c = this.env.ss.categories[cid];
        if (c && (eventViewFilter.testCategory(cid) || eventViewFilter.testCategoryIndeterminate(cid)))
        {
          let count = 0; es.forEach(eid => { count += stats.get(eid) || 0 });
          boxes.push(
              <div className={classes.simpleRow}>
                {categorybox(cid)}
                <Mui.Typography className={classes.buttonText}>{`${c.name} (${count})`}</Mui.Typography>
              </div>
            );
          es.forEach(eid => {
              let e = this.env.ss.events[eid];
              if (e && eventViewFilter.testEvent(eid))
              {
                boxes.push(
                    <div className={classes.simpleRow}>
                      {eventbox(eid)}
                      <Mui.Typography className={classes.buttonText}>{`${e.name} (${stats.get(eid) || 0})`}</Mui.Typography>
                    </div>
                  );
              }
            });
        }
      });

    return (
        <Mui.Popover
          open={true}
          style={{width: ''}}
          anchorEl={filterEl}
          onClose={() => { this.setState({ filterEl: null }) }}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          >
          <Mui.TextField
            variant='standard'
            className={classes.searchControl}
            InputProps={{
              id: 'searchcontrol',
              name: 'search',
              autoComplete: 'off',
              autoCorrect: 'off',
              autoCapitalize: 'off',
              spellCheck: false,
              classes: {input: classes.searchInput},
              endAdornment:
                <Mui.InputAdornment position='end'>
                  <Mui.IconButton color='inherit' className={''} size={'small'} onClick={() => { this.setFilterText('')}}
                   >
                    <Icons.CancelOutlined fontSize={'small'} />
                  </Mui.IconButton>
                </Mui.InputAdornment>
            }}
            value={filterString}
            autoFocus
            placeholder={'Filter Events'}
            onChange={this.handleFilterText}
          />
          <div className={classes.popoverViewPort}>
            {boxes}
          </div>
         </Mui.Popover>
      );
  }

  setFilterText(s: string): void
  {
    const {eventFilter} = this.props;
    const {eventViewFilter} = this.state;

    this.setState({ filterString: s, eventViewFilter });

    s = s.trim().toLowerCase();
    if (! s)
    {
      eventFilter.reset(true);
      eventViewFilter.reset(true);
    }
    else
    {
      eventViewFilter.reset(false);
      eventFilter.reset(false);
      this.env.ss.eventsByCategory.forEach((es: string[], cid: string) => {
          let c = this.env.ss.categories[cid];
          if (c)
          {
            if (c.name.toLowerCase().includes(s))
            {
              es.forEach(eid => { eventFilter.setEvent(eid) });
              es.forEach(eid => { eventViewFilter.setEvent(eid) });
            }
            else
            {
              es.forEach(eid => {
                  let e = this.env.ss.events[eid];
                  if (e && e.name.includes(s))
                  {
                    eventFilter.setEvent(eid);
                    eventViewFilter.setEvent(eid);
                  }
                });
            }
          }
        });
    }
  }

  handleFilterText(e: any): void
  {
    this.setFilterText(e.target.value);
  }

  renderListViewToggle(): any
  {
    const {classes, actions, viewState, listView} = this.props;

    let vs = viewState.top;
    if (vs !== 'month' && vs !== 'year') return null;

    let button = (id: string, icon: any) => { return (
        <Mui.ToggleButton className={classes.subviewToggle}
          sx={sxToggle}
          onChange={() => { actions.fire(ClientActions.ListView, id)}}
          selected={listView === id}
          size={'small'}
          value={id}>
            {icon}
        </Mui.ToggleButton>) };
    
    return (
        <div>
          {button('grid', <Icons.GridView />)}
          {button('list', <Icons.ViewCompact />)}
          {button('details', <Icons.ViewList />)}
        </div>
      );
  }

  renderListToggle(): any
  {
    const {classes} = this.props;

    return ( <div>
              <div className={classes.spreadRow}>
                {this.renderFilterButton()}
                <div className={classes.growAble} />
                {this.renderListViewToggle()}
              </div>
              {this.renderFilterPopover()}
            </div> )
  }

  renderEventButton(): any
  {
    const {classes, actions, viewState, eventFilter} = this.props;

    let act = () => {
        eventFilter.reset(true);
        actions.fire(ClientActions.Events);
      };

    let vs = viewState.top;
    let disabled = (vs === 'events' || vs === 'event');
    return (
      <Mui.Tooltip title={getTooltip('Create or modify your events and categories.')}>
        <div>
          <Mui.IconButton
            color='inherit'
            aria-label='Create or modify your events and categories.'
            onClick={act}
            size='small'
            disabled={disabled}
           >
            <Icons.Settings fontSize={'small'} />
            <Mui.Typography className={classes.buttonText}>
              Events
            </Mui.Typography>
          </Mui.IconButton>
        </div>
      </Mui.Tooltip> )
  }

  renderMainToggle(): any
  {
    const {classes, actions, viewState} = this.props;

    if (this.env.ss.isAnon || this.env.ss.isUnverified)
      return null;

    let vs = viewState.top;

    let button = (id: string, label: string) => { return (
        <div className={classes.viewToggle}>
          <Mui.ToggleButton onChange={this.handleMainToggle}
            sx={sxToggle}
            selected={vs === id}
            size={'small'}
            value={id}>
              {label}
          </Mui.ToggleButton>
        </div>)};

    return (<div className={classNames(classes.spreadRow)}>
              {button('day', 'Today')}
              {button('month', 'Month')}
              {button('year', 'Year')}
              {button('stats', 'Stats')}
              {button('comments', 'Notes')}
              <div className={classes.growAble}></div>
              {this.renderEventButton()}
            </div> );
  }

  renderReadOnly(): any
  {
    const {classes, actions, viewState, today, readonly} = this.props;

    if (this.env.ss.isAnon || this.env.ss.isUnverified)
      return null;

    let vs = viewState.top;
    if (vs !== 'day') return null;

    if (today) return null;

    const icon = readonly
      ? <Mui.Tooltip title={getTooltip('Edit the events for this day')}>
            <Mui.IconButton
              color='inherit'
              aria-label='Edit the events for this day'
              onClick={() => actions.fire(ClientActions.ToggleReadOnly)}
              size='small'
             >
              <Icons.ModeEdit fontSize={'small'} />
            </Mui.IconButton>
          </Mui.Tooltip>
      : <Mui.Tooltip title={getTooltip('Finish editing the events for this day')}>
            <Mui.IconButton
              color='inherit'
              aria-label='Finish editing the events for this day'
              onClick={() => actions.fire(ClientActions.ToggleReadOnly)}
              size='small'
             >
              <Icons.Check fontSize={'small'} />
            </Mui.IconButton>
          </Mui.Tooltip>

    return ( <div className={classNames(classes.spreadRow, classes.readonlyToggle)}>
              {icon}
              <div className={classes.growAble}></div>
            </div> )

  }

  renderDay(): any
  {
    const {classes, actions, viewState, dateString, readonly} = this.props;
    const {textInit} = this.state;

    if (this.env.ss.isAnon || this.env.ss.isUnverified)
      return null;

    let vs = viewState.top;
    if (vs !== 'day') return null;

    let dayparams = { actions, dateString, readonly, comment: textInit.comment };

    return (
        <div className={classes.viewCanvas}>
          <div className={classes.spreadRow}>
            {this.renderPrevDate()}
            <div className={classes.growAble} />
            <Mui.Typography className={classes.viewTitle}>{DU.toLongDate(dateString)}</Mui.Typography>
            <div className={classes.growAble} />
            {this.renderNextDate()}
          </div>
          <div className={classes.viewPort}>
            <div className={classes.centerBlock} >
              <DayView {...dayparams} />
            </div>
          </div>
        </div> )
  }

  renderInstanceDayofMonthSnip(i: CT.Instance): any
  {
    const {classes, eventFilter, listView} = this.props;

    if (!i.on) return null;
    let e = this.env.ss.events[i.eventid];
    if (! e) return null;
    if (! eventFilter.testEvent(i.eventid)) return null;
    if (listView === 'details')
      return ( <div>{e.name}<span>&nbsp;</span></div> );
    else
      return ( <div className={classes.daySnip} style={{backgroundColor: e.color}}> {this.initialsOf(e.name)} </div> );
  }

  renderInstanceDayofYearSnip(i: CT.Instance): any
  {
    const {classes, eventFilter, listView} = this.props;

    if (!i.on) return null;
    let e = this.env.ss.events[i.eventid];
    if (! e) return null;
    if (! eventFilter.testEvent(i.eventid)) return null;
    if (listView === 'details')
      return ( <div>{e.name}<span>&nbsp;</span></div> );
    else
      return ( <div className={classes.dayYearSnip} style={{backgroundColor: e.color}}> </div> );
  }

  renderDayofMonthTile(year: string, dayKey: string, isToday: boolean): any
  {
    const {classes, actions, designSize, designWidth} = this.props;
    const ix = this.env.ss.instancesByKey(year, dayKey);
    const comment = this.env.ss.commentByKey(year, dayKey).trim();
    const snips = Object.values(ix).map((i: CT.Instance) => {return this.renderInstanceDayofMonthSnip(i)}).filter((a: any) => !!a);
    const tileD = Math.floor((designWidth-4) / 7);
    const tileStyle = { width: `${tileD}px`, height: `${tileD}px` }
    const classAll = classNames(classes.dayTile);
    const daymonth = dayKey.split('.');
    const style = Util.shallowAssign(isToday ? { backgroundColor: '#fafad2' } : {}, tileStyle);

    const tile = (
        <div className={classAll} style={style}
             onClick={(e) => { actions.fire(ClientActions.Day, this.env.ss.toDateFromKey(year, dayKey)) }}>
          <div className={classes.dayText}>{daymonth[0]}</div>
            {snips}
        </div>
      );

    if (comment)
      return ( <Mui.Tooltip title={getTooltip(comment.trim())}>{tile}</Mui.Tooltip> )
    else
      return tile;
  }

  renderDayofYearTile(year: string, dayKey: string, isToday: boolean): any
  {
    const {classes, actions, designSize, designWidth} = this.props;
    const ix = this.env.ss.instancesByKey(year, dayKey);
    const snips = Object.values(ix).map((i: CT.Instance) => {return this.renderInstanceDayofYearSnip(i)}).filter((a: any) => !!a);
    const classSize = classes.tileYear;
    const classAll = classNames(classes.dayTile, classSize);
    const daymonth = dayKey.split('.');
    const text = daymonth[0] == '1' ? DU.InitialMonths[Number(daymonth[1])] : '';
    const style = isToday ? { backgroundColor: '#fafad2' } : {}

    return (
        <div className={classAll} style={style}
             onClick={(e) => { actions.fire(ClientActions.Day, this.env.ss.toDateFromKey(year, dayKey)) }}>
          <div className={classes.dayYearText}>{text}</div>
            {snips}
        </div>
     )
  }

  renderMonthGrid(): any
  {
    const {classes, actions, viewState, dateString, designSize, designWidth} = this.props;

    let sd = SD.parseDate(dateString);
    sd.day = 1;
    sd = SD.parseDate(SD.formatDate(sd));
    let sdToday = SD.parseDate(SD.nowString());

    const tileD = Math.floor((designWidth-4) / 7);  // -4 is a fudge factor for iPhone which ends up too wide
    const tileStyle = { width: `${tileD}px`, height: `${tileD}px` }
    const tileStyleHeader = { width: `${tileD}px`, height: `${tileD/2}px` }
    const classHeader = classNames(classes.headerTile);
    const classEmpty = classNames(classes.emptyTile);
    let header = (
      <div className={classes.spreadRow}>
        {DU.DaysOfWeekCapital.map(day => { return (<div className={classHeader} style={tileStyleHeader}>{day}</div>) })}
      </div> );

    let start = - sd.dayofweek;
    let len = DU.monthLength(dateString);
    let rows: any[] = [];
    for (let r = 0; r < 5; r++)
    {
      let days: any[] = [];
      for (let d = 0; d < 7; d++)
      {
        if (start < 0 || start >= len)
          days.push(<div className={classEmpty} style={tileStyle}></div>);
        else
        {
          let dayKey = `${start+1}.${sd.month}`;
          let isToday = start+1 == sdToday.day && sd.month == sdToday.month && sd.year == sdToday.year;
          days.push(this.renderDayofMonthTile(String(sd.year), dayKey, isToday));
        }
        start++;
      }
      rows.push(<div className={classes.spreadRow}>{days}</div>);
    }

    return (
        <div>
          {header}
          {rows}
        </div>
      )
  }

  renderMonthRows(dateString: SD.DateString): any[]
  {
    const {classes, actions, viewState, eventFilter, designSize, designWidth} = this.props;

    let isall = eventFilter.isAll();
    let sd = SD.parseDate(dateString);
    let len = DU.monthLength(dateString);
    let rows: any[] = [];
    for (let i = 1; i <= len; i++)
    {
      sd.day = i;
      let s = SD.formatDate(sd);
      let label = DU.toMonthDate(s);
      sd = SD.parseDate(s);
      let dayKey = `${sd.day}.${sd.month}`;
      const ix = this.env.ss.instancesByKey(String(sd.year), dayKey);
      const snips = Object.values(ix).map((i: CT.Instance) => {return this.renderInstanceDayofMonthSnip(i)}).filter((a: any) => !!a);
      if (isall || snips.length)
      {
        let c = classNames(sd.day == 1 ? classes.cellTopBorder : classes.cellNoBorder, classes.cellTight);
        rows.push(
            <Mui.TableRow>
              <Mui.TableCell className={c}>{sd.dayofweek === 1 ? 'Mon' : ''}</Mui.TableCell>
              <Mui.TableCell className={c}>{DU.ShortMonths[sd.month]}</Mui.TableCell>
              <Mui.TableCell className={c}>{sd.day}</Mui.TableCell>
              <Mui.TableCell className={c}><div className={classes.simpleWrapRow}>{snips}</div></Mui.TableCell>
            </Mui.TableRow>
          )
      }
    }

    return rows
  }

  renderMonthList(): any
  {
    const {classes, dateString} = this.props;

    return (
        <Mui.Table className={classes.fontSmall}><Mui.TableBody>
          {this.renderMonthRows(dateString)}
        </Mui.TableBody></Mui.Table>
      )
  }

  renderMonth(): any
  {
    const {classes, actions, viewState, listView, dateString} = this.props;

    if (this.env.ss.isAnon || this.env.ss.isUnverified)
      return null;

    let vs = viewState.top;
    if (vs !== 'month') return null;
    let content = listView === 'grid' ? this.renderMonthGrid() : this.renderMonthList();

    return (
      <div className={classes.viewCanvas}>
        {this.renderListToggle()}
        <Mui.Typography className={classes.viewTitle}>{DU.toLongMonth(dateString)}</Mui.Typography>
        <div className={classes.viewPort}>
          <div className={classes.centerBlock}>
            {this.renderPrevDate()}
            <div className={classes.centerBlock}>
              {content}
            </div>
            {this.renderNextDate()}
          </div>
        </div>
      </div> )
  }

  renderComments(): any
  {
    const {classes, actions, viewState, dateString} = this.props;

    if (this.env.ss.isAnon || this.env.ss.isUnverified)
      return null;

    let vs = viewState.top;
    if (vs !== 'comments') return null;
    let sd = SD.parseDate(dateString);
    let year = sd.year;

    let rows: any[] = [];
    let c = this.env.ss.commentsByYear[String(year)];
    let lastMonth = -1;
    if (c?.days)
    {
      rows = Object.keys(c.days).sort(SS.sortDayKey).map((k: string) => {
          let comment = c.days[k];
          if (comment) comment = comment.trim();
          if (comment)
          {
            let a = k.split('.');
            sd.day = Number(a[0]);
            sd.month = Number(a[1]);
            sd = SD.parseDate(SD.formatDate(sd));
            let cls = classNames(sd.month != lastMonth ? classes.cellTopBorder : classes.cellNoBorder, classes.cellTight);
            lastMonth = sd.month;
            return (
                <Mui.TableRow>
                  <Mui.TableCell className={cls}>{DU.DaysOfWeekCapital[sd.dayofweek]}</Mui.TableCell>
                  <Mui.TableCell className={cls}>{DU.ShortMonths[sd.month]}</Mui.TableCell>
                  <Mui.TableCell className={cls}>{sd.day}</Mui.TableCell>
                  <Mui.TableCell className={cls}><div className={classes.simpleWrapRow}>{comment}</div></Mui.TableCell>
                </Mui.TableRow>
              )
          }
          else
            return null;
        }).filter((r: any) => r != null);
    }
    let content = ( <Mui.Table><Mui.TableBody>{rows}</Mui.TableBody></Mui.Table> );

    return (
      <div className={classes.viewCanvas}>
        <div className={classes.spreadRow}>
          <Mui.Typography className={classes.viewTitle}>{year}</Mui.Typography>
          <div className={classes.growAble}></div>
          <Mui.Tooltip title={getTooltip('Download Notes as CSV File')}>
            <Mui.IconButton
              color='inherit'
              aria-label='Download Notes as CSV File'
              onClick={() => actions.fire(ClientActions.ExportComments)}
              size='small'
             >
              <Icons.Download />
            </Mui.IconButton>
          </Mui.Tooltip>
        </div>
        <div className={classes.viewPort}>
          <div className={classes.centerBlock}>
            {this.renderPrevDate()}
            <div className={classes.centerBlock}>
              {content}
            </div>
            {this.renderNextDate()}
          </div>
        </div>
      </div> )
  }

  renderYearGrid(): any
  {
    const {classes, dateString} = this.props;

    let sd = SD.parseDate(dateString);
    let year = sd.year;
    sd.day = 1;
    sd.month = 0;
    let s = SD.formatDate(sd);
    let sdToday = SD.parseDate(SD.nowString());

    let days: any[] = [];
    do
    {
      let isToday = sd.day == sdToday.day && sd.month == sdToday.month && sd.year == sdToday.year;
      let dayKey = `${sd.day}.${sd.month}`;
      days.push(this.renderDayofYearTile(String(sd.year), dayKey, isToday));
      s = DU.addDays(s, 1);
      sd = SD.parseDate(s);
    }
    while (sd.year == year);

    return ( <div className={classes.wrapRow}>{days}</div> )
  }

  renderYearList(): any
  {
    const {classes, dateString} = this.props;

    let sd = SD.parseDate(dateString);
    let year = sd.year;
    sd.day = 1;
    let allRows: any[] = [];
    for (let m = 0; m < 12; m++)
    {
      sd.month = m;
      allRows = [...allRows, ...this.renderMonthRows(SD.formatDate(sd))];
    }
    return (<Mui.Table className={classes.fontSmall}><Mui.TableBody>{allRows}</Mui.TableBody></Mui.Table>)
  }

  renderYear(): any
  {
    const {classes, viewState, listView, dateString} = this.props;

    if (this.env.ss.isAnon || this.env.ss.isUnverified)
      return null;

    let vs = viewState.top;
    if (vs !== 'year') return null;

    let content = listView === 'grid' ? this.renderYearGrid() : this.renderYearList();
    let sd = SD.parseDate(dateString);
    let year = sd.year;

    return (
      <div className={classes.viewCanvas}>
        {this.renderListToggle()}
        <Mui.Typography className={classes.viewTitle}>{year}</Mui.Typography>
        <div className={classes.viewPort}>
          <div className={classes.centerBlock}>
            {this.renderPrevDate()}
            {content}
            {this.renderNextDate()}
          </div>
        </div>
      </div> )
  }

  renderStats(): any
  {
    const {classes, actions, viewState, statView, dateString} = this.props;

    if (this.env.ss.isAnon || this.env.ss.isUnverified)
      return null;

    let vs = viewState.top;
    if (vs !== 'stats') return null;
    let sd = SD.parseDate(dateString);

    let button = (id: string, label: string) => { return (
        <Mui.ToggleButton className={classes.subviewToggle}
          sx={sxToggle}
          onChange={() => { actions.fire(ClientActions.StatsView, id)}}
          selected={statView === id}
          size={'small'}
          value={id}>
            {label}
        </Mui.ToggleButton>) };


    let rows: any[] = [];
    if (statView === 'byyear' || statView === 'todate')
    {
      rows.push(
        <Mui.TableRow><Mui.TableCell className={classes.cellNoBorder}>
          <div className={classes.statH1}>{statView==='byyear' ? String(sd.year) : 'To Date'}</div>
        </Mui.TableCell></Mui.TableRow>
        );
      let stats = this.env.ss.stats(statView==='byyear' ? String(sd.year) : undefined);
      let withNames = this.env.ss.statsWithNames(stats).filter((c: SS.StatWithNames) => c.n > 0);
      let lastcategory = '';
      withNames.forEach((c: SS.StatWithNames, i: number) => {
          let cellClass = (i+1 == withNames.length || c.c != withNames[i+1].c) ? '' : classes.cellNoBorder;
          rows.push(
              <Mui.TableRow>
                <Mui.TableCell className={cellClass} />
                <Mui.TableCell className={cellClass}>
                  {c.c !== lastcategory
                   ? <div className={classes.statH3}>{c.c}</div>
                   : null}
                </Mui.TableCell>
                <Mui.TableCell className={cellClass}>
                  <div className={classes.statH4}>{c.e}</div>
                </Mui.TableCell>
                <Mui.TableCell className={cellClass}>
                  <div className={classes.statH4}>{`${c.n}`}</div>
                </Mui.TableCell>
              </Mui.TableRow>
            );
          lastcategory = c.c;
        });
    }
    else if (statView === 'bymonth')
    {
      rows.push(
        <Mui.TableRow><Mui.TableCell className={classes.cellNoBorder}>
          <div className={classes.statH1}>{sd.year}</div>
        </Mui.TableCell></Mui.TableRow>
        );
      for (let m = 0; m < 12; m++)
      {
        let stats = this.env.ss.stats(String(sd.year), String(m));
        let withNames = this.env.ss.statsWithNames(stats).filter((c: SS.StatWithNames) => c.n > 0);
        if (withNames.length)
        {
          rows.push(
              <Mui.TableRow>
                <Mui.TableCell className={classes.cellNoBorder}>
                  <div className={classes.statH2}>{DU.Months[m]}</div>
                </Mui.TableCell>
              </Mui.TableRow>
            );
          let lastcategory = '';
          withNames.forEach((c: SS.StatWithNames, i: number) => {
              let cellClass = (i+1 == withNames.length || c.c != withNames[i+1].c) ? '' : classes.cellNoBorder;
              rows.push(
                  <Mui.TableRow>
                    <Mui.TableCell className={cellClass} />
                    <Mui.TableCell className={cellClass}>
                      {c.c !== lastcategory
                       ? <div className={classes.statH3}>{c.c}</div>
                       : null}
                    </Mui.TableCell>
                    <Mui.TableCell className={cellClass}>
                      <div className={classes.statH4}>{c.e}</div>
                    </Mui.TableCell>
                    <Mui.TableCell className={cellClass}>
                      <div className={classes.statH4}>{`${c.n}`}</div>
                    </Mui.TableCell>
                  </Mui.TableRow>
                );
              lastcategory = c.c;
            });
        }
      }
    }

    return (
      <div className={classes.viewCanvas}>
        <div className={classes.viewTitle}>
          <div className={classes.spreadRow}>
            {button('bymonth', 'By Month')}
            {button('byyear', 'By Year')}
            {button('todate', 'To Date')}
            <div className={classes.growAble}></div>
            <Mui.Tooltip title={getTooltip('Download Events as CSV File')}>
              <Mui.IconButton
                color='inherit'
                aria-label='Download CSV File'
                onClick={() => actions.fire(ClientActions.ExportStats)}
                size='small'
               >
                <Icons.Download />
              </Mui.IconButton>
            </Mui.Tooltip>
          </div>
        </div>
        <div className={classNames(classes.staticTextDisplay, classes.viewPort)}>
          <Mui.Table><Mui.TableBody>
            {rows}
          </Mui.TableBody></Mui.Table>
        </div>
      </div> )
  }

  renderPrevDate(): any
  {
    const {actions, designSize, isTouch} = this.props;

    if (isTouch || designSize <= DW.PHONEPLUS)
      return null;

    return ( <Mui.Tooltip title={getTooltip('Previous interval')}>
        <Mui.IconButton
          color='inherit'
          aria-label='back'
          onClick={() => actions.fire(ClientActions.PrevDate)}
          size='small'
         >
          <Icons.ArrowBack />
        </Mui.IconButton>
      </Mui.Tooltip> )
  }

  renderNextDate(): any
  {
    const {actions, designSize, isTouch} = this.props;

    if (isTouch || designSize <= DW.PHONEPLUS)
      return null;

    return ( <Mui.Tooltip title={getTooltip('Next interval')}>
        <Mui.IconButton
          color='inherit'
          aria-label='forward'
          onClick={() => actions.fire(ClientActions.NextDate)}
          size='small'
         >
          <Icons.ArrowForward />
        </Mui.IconButton>
      </Mui.Tooltip> )
  }

  renderEvents(): any
  {
    const {classes, actions, viewState} = this.props;

    if (this.env.ss.isAnon || this.env.ss.isUnverified)
      return null;

    let vs = viewState.top;
    if (vs !== 'events') return null;

    let eventButton = (e: CT.Event) => { return (
         e
         ? <Mui.ToggleButton className={classes.eventButton} onChange={() => { actions.fire(ClientActions.Event, e.id) }}
            style={{color: e.color, borderColor: e.color}}
            sx={sxToggle}
            selected={false}
            size={'small'}
            value={e.id}>
              {e.name}
           </Mui.ToggleButton>
         : <span>Loading</span> )
      };
    let categoryButton = (c: CT.Category) => { return (
         c
         ? <Mui.ToggleButton className={classes.eventButton} onChange={() => { actions.fire(ClientActions.Category, c.id) }}
             sx={sxToggle}
             selected={false}
             size={'small'}
             value={c.id}>
               {c.name}
           </Mui.ToggleButton>
         : <span>Loading</span> )
      };

    let categories = Array.from(this.env.ss.eventsByCategory.keys()).sort(this.env.ss.sortCategory).map(idCat => {
        let events = this.env.ss.eventsByCategory.get(idCat).sort(this.env.ss.sortEvent).map(idEvent => { return eventButton(this.env.ss.events[idEvent]) });
        return (
          <Mui.TableRow className={classNames(classes.categoryRow)}>
            <Mui.TableCell className={classes.categoryColumn}>
              {categoryButton(this.env.ss.categories[idCat])}
            </Mui.TableCell>
            <Mui.TableCell className={classes.eventColumn}>
              {events}
            </Mui.TableCell>
          </Mui.TableRow>)
      });

    return (
      <div className={classes.viewCanvas}>
        <div className={classes.spreadRow}>
          <Mui.Typography className={classes.viewTitle}>
            {categories.length
              ? <span>Your Events</span>
              : <span>Add your own events!</span>
            }
          </Mui.Typography>
          <div className={classes.growAble} />
          {this.renderAdd()}
        </div>
        <div className={classes.viewPort}>
          <Mui.Table>
            <Mui.TableBody>
              <div className={classes.growAble}>
                {categories}
              </div>
            </Mui.TableBody>
          </Mui.Table>
        </div>
      </div>)
  }

  renderEvent(): any
  {
    const {actions, viewState, eventEdit} = this.props;

    if (this.env.ss.isAnon || this.env.ss.isUnverified)
      return null;

    let vs = viewState.top;
    if (vs !== 'event' || !eventEdit) return null;

    const textInit: ClientActions.TextInit = {
      name: eventEdit.name || '',
      description: eventEdit.description || '',
      category: eventEdit.category || this.env.ss.categories[eventEdit.idCat]?.name || '',
      };
    delete eventEdit.category; // only used temporarily
    let params = { actions, textInit, eventEdit };
    return <EventView {...params} />;
  }

  renderCategory(): any
  {
    const {actions, viewState, categoryEdit} = this.props;

    if (this.env.ss.isAnon || this.env.ss.isUnverified)
      return null;

    let vs = viewState.top;
    if (vs !== 'category' || !categoryEdit) return null;

    const textInit: ClientActions.TextInit = {
      name: categoryEdit.name || '',
      description: categoryEdit.description || '',
      };
    let params = { actions, textInit, categoryEdit };
    return <CategoryView {...params} />;
  }

  renderLogin(): any
  {
    const {actions, viewState} = this.props;

    let vs = viewState.top;
    if (vs !== 'login' && vs !== 'signup') return null;

    const textInit: ClientActions.TextInit = {
      signup: vs === 'signup' ? 'signup' : null,
      name: '',
      email: '',
      password: '',
      };
     const params = { actions, textInit };
     return ( <LoginView {...params} /> );
  }

  renderForgot(): any
  {
    const {actions, viewState} = this.props;

    let vs = viewState.top;
    if (vs !== 'forgot') return null;

    const textInit: ClientActions.TextInit = { email: '' };
     const params = { actions, textInit };
     return ( <ForgotView {...params} /> );
  }

  renderReset(): any
  {
    const {actions, viewState, resetGUID} = this.props;

    let vs = viewState.top;
    if (vs !== 'reset') return null;

    const textInit: ClientActions.TextInit = { resetGUID, password: '' };
     const params = { actions, textInit };
     return ( <ResetView {...params} /> );
  }

  toggleColor(color: string): void
  {
    const {actions, eventEdit} = this.props;

    eventEdit.color = color;
    actions.fire(ClientActions.PopView);
  }

  renderColors(): any
  {
    const {classes, actions, eventEdit, viewState} = this.props;

    if (this.env.ss.isAnon || this.env.ss.isUnverified)
      return null;

    let vs = viewState.top;
    if (vs !== 'colors') return null;

    let button = (color: string) => { return (
        <Mui.ToggleButton className={classes.eventButton} onChange={(e: any) => { this.toggleColor(color) }}
          style={{color: color, borderColor: color}}
          sx={sxToggle}
          selected={eventEdit.color === color}
          size={'small'}
          value={color}>
            {color}
        </Mui.ToggleButton>) };
    let buttons = this.env.ss.allColors().map(color => button(color));

    return (
      <div  id={'eventview'} className={classes.mainCanvasWrapper}>
        <div className={classes.mainCanvasInner}>
          <div className={classNames(classes.fontMedium)}>
            <Mui.Tooltip title={getTooltip('Return to event list')}>
              <Mui.IconButton
                color='inherit'
                aria-label='back'
                onClick={() => actions.fire(ClientActions.PopView)}
                size='small'
               >
                <Icons.Clear />
               </Mui.IconButton>
            </Mui.Tooltip>
          </div>
          <div className={classes.spreadRow}>
            <div className={classes.growAble}>
              {buttons}
            </div>
          </div>
          <div className={classes.buttonRow}>
            <div className={classes.growAble}></div>
            <Mui.Button variant='outlined' onClick={() => { actions.fire(ClientActions.PopView) }}>
              Cancel
            </Mui.Button>
          </div>
        </div>
      </div>
    );
  }

  renderMain(): any
  {
    const {classes, viewState} = this.props;

    const top = viewState.top;

    if (this.env.ss.isAnon || this.env.ss.isUnverified)
      if (top === 'about' || top === 'help' || top === 'privacy'
          || top === 'login' || top === 'signup' || top === 'forgot' || top === 'reset')
        return (
          <div id={'mainview'} className={classes.mainCanvasWrapper}>
            <div className={classes.mainCanvasInner}>
              {this.renderAbout()}
              {this.renderHelp()}
              {this.renderPrivacy()}
              {this.renderLogin()}
              {this.renderForgot()}
              {this.renderReset()}
            </div>
          </div>
          );
      else
        return null;

    return (
        <div id={'mainview'} className={classes.mainCanvasWrapper}>
          {this.renderMainToggle()}
          {this.renderReadOnly()}
          <div className={classes.mainCanvasInner}>
            {this.renderDay()}
            {this.renderMonth()}
            {this.renderYear()}
            {this.renderStats()}
            {this.renderComments()}
            {this.renderEvents()}
            {this.renderEvent()}
            {this.renderLogin()}
            {this.renderForgot()}
            {this.renderReset()}
            {this.renderCategory()}
            {this.renderColors()}
            {this.renderAbout()}
            {this.renderHelp()}
            {this.renderPrivacy()}
          </div>
        </div>
      );

  }

  renderLoading(): any
  {
    const {classes, actions} = this.props;

    return (
        <div id={'loadingview'} className={classes.mainCanvasWrapper}>
          <div className={classes.mainCanvasInner}>
            <div className={classNames(classes.fontMedium)}>
              <Mui.Tooltip title={getTooltip('Return to poll list')}>
                <Mui.IconButton
                  color='inherit'
                  aria-label='back'
                  onClick={() => actions.fire(ClientActions.PopView)}
                  size='small'
                 >
                  <Icons.Clear />
                 </Mui.IconButton>
              </Mui.Tooltip>
            </div>
            <div className={classes.mainTextDisplay}>Loading...</div>
            <div>&nbsp;</div>
            <div><Mui.CircularProgress /></div>
          </div>
        </div>
    );
  }

  renderViewers(): any
  {
    const {actions, progressState, alertState} = this.props;

    return (<Viewer actions={actions} alertState={alertState} progressState={progressState} />);
  }

  renderPopover(): any
  {
    const {classes, actions, elPopoverOn, popoverState} = this.props;

    if (!elPopoverOn) return null;

    return (<Mui.Popover
      open={true}
      anchorEl={elPopoverOn}
      >
        <div className={classes.popoverOuter}>
          <div className={classes.popoverInner}>{popoverState.message}</div>
        </div>
      </Mui.Popover>)
  }

  renderAboutMenu(): any
  {
    const {classes, actions, elAboutMenuOn} = this.props;
    const {env} = actions;

    let menu = elAboutMenuOn
      ? <AboutMenu actions={actions} elOn={elAboutMenuOn} />
      : null;

    return (
      <div>
        <Mui.Tooltip title={getTooltip('About What I Did')}>
          <Mui.IconButton
            color="inherit"
            area-label="Open about menu"
            onClick={(e: any) => { actions.fire(ClientActions.AboutMenu) }}
            size="medium"
            className={classes.appBarButton}
            >
            <Icons.Menu className={classes.aboutMenu} fontSize={'medium'} id={'aboutMenu'} style={{transform: 'scale(1.2)'}} />
          </Mui.IconButton>
        </Mui.Tooltip>
        {menu}
      </div>
    );
  }

  renderProfile(): any
  {
    const {classes, actions, elProfileMenuOn} = this.props;
    const {env} = actions;

    let menu = elProfileMenuOn
      ? <ProfileMenu actions={actions} elOn={elProfileMenuOn} />
      : null;

    return (
      <div>
        <Mui.Tooltip title={getTooltip(env.ss.isAnon ? 'Account Login' : `Account info for ${env.ss.user.name}`)}>
          <Mui.IconButton
            color="inherit"
            area-label="Open profile menu"
            onClick={(e: any) => { actions.fire(ClientActions.ProfileMenu) }}
            size="medium"
            className={classes.appBarButton}
            >
            <Icons.AccountCircle fontSize={'medium'} id={'profileMenu'} style={{transform: 'scale(1.2)'}} />
            &nbsp;&nbsp;
            {env.ss.user.name}
          </Mui.IconButton>
        </Mui.Tooltip>
        {menu}
      </div>
    );
  }

  renderAdd(): any
  {
    const {classes, actions} = this.props;

    if (this.env.ss.isAnon || this.env.ss.isUnverified)
      return null;

    return (
        <div style={{marginTop: '8px'}}>
          <Mui.Fab color='primary' size={'small'} aria-label='Add Event' onClick={() => actions.fire(ClientActions.Event)} >
            <Icons.Add fontSize={'small'} />
          </Mui.Fab>
        </div>
      );
  }

  handleHome(): void
  {
    const {actions} = this.props;

    actions.fire(ClientActions.Home);
  }

  renderAppBar(): any
  {
    const {classes, actions, viewState} = this.props;

    return (
      <Mui.AppBar position='fixed' className={classNames(classes.appBar)}
          classes={{colorPrimary: classes.appBarColorPrimary}}>
        <Mui.Toolbar disableGutters={true} className={classes.toolBar}>
          {this.renderAboutMenu()}
          {this.renderPopover()}
          <div className={classes.title} onClick={this.handleHome}>What I Did</div>
          <div style={{width: '100%'}}>&nbsp;</div>
          <Mui.Tooltip title={getTooltip('Display help information.')}>
            <Mui.IconButton
              color="inherit"
              area-label="Display help information"
              onClick={(e: any) => { actions.fire(ClientActions.Help) }}
              size="medium"
              className={classes.appBarButton}
              >
              <Icons.Help fontSize={'medium'} style={{transform: 'scale(1.2)'}} />
            </Mui.IconButton>
          </Mui.Tooltip>
          <Mui.Tooltip title={getTooltip('Provide feedback on bugs or features.')}>
            <Mui.IconButton
              color="inherit"
              area-label="Open profile menu"
              onClick={(e: any) => { actions.fire(ClientActions.Open, { dialogname: 'feedback', textInit: {} }) }}
              size="medium"
              className={classes.appBarButton}
              >
              <Icons.Feedback fontSize={'medium'} style={{transform: 'scale(1.2)'}} />
            </Mui.IconButton>
          </Mui.Tooltip>
          {this.renderProfile()}
        </Mui.Toolbar>
      </Mui.AppBar>
    );
  }

  renderTextView(name: string): any
  {
    const {classes, actions, viewState} = this.props;

    if (viewState.top !== name) return null;

    let text = actions.env.ss.textData(name) || `+${name}\n\nLoading...`;
    let lines = text.split('\n');
    let divs = lines.map(line => {
        let cname = classes.staticTextDisplay;
        if (/^\+/.test(line))
        {
          line = line.substring(1);
          cname = classes.staticTextTitle;
        }
        return <div className={cname}>{line}</div>
      });

    return (
        <div className={classes.viewCanvas}>
          <div className={classNames(classes.fontMedium)}>
            <Mui.Tooltip title={getTooltip('Return main view')}>
              <Mui.IconButton
                color='inherit'
                aria-label='back'
                onClick={() => actions.fire(ClientActions.PopView)}
                size='small'
               >
                <Icons.Clear />
               </Mui.IconButton>
            </Mui.Tooltip>
          </div>
          <div className={classes.viewPort}>
            {divs}
          </div>
        </div>
    );
  }

  renderAbout(): any
  {
    return this.renderTextView('about');
  }

  renderHelp(): any
  {
    return this.renderTextView('help');
  }

  renderPrivacy(): any
  {
    return this.renderTextView('privacy');
  }

  render(): any
  {
    const { classes, actions } = this.props;
    const { filterString } = this.state;

    let root = (
        <div className={classes.root}>
          {this.renderAppBar()}
          {this.renderViewers()}
          {this.renderAnon()}
          {this.renderMain()}
          {this.renderWelcome()}
          {this.renderUnverified()}
        </div>
    );

    return (
        <SwipeableComponent
          root={root}
          onSwipedLeft={this.onSwipedLeft}
          onSwipedRight={this.onSwipedRight}
          />
      );
  }
}

export let MaterialTheme: any = Mui.createTheme(
  {
    transitions: {
      // So we have transition: none; everywhere
      create: () => 'none',
    },
    palette: {
      primary: {
        light: Mui.indigo['200'],
        main: Mui.indigo['700'],
        dark: Mui.indigo['900'],
        contrastText: '#ebf0f0',
      },
      secondary: {
        light: Mui.red['200'],
        main: Mui.red['600'],
        dark: Mui.red['800'],
        contrastText: '#ebf0f0',
      },
      background: {
        default: '#ffffff',
      },
    },
  }
);

let StyledMaterialApp: any = Mui.withStyles(AppStyles, { defaultTheme: MaterialTheme, withTheme: true })(InternalMaterialApp);
export const MaterialApp: new () => React.Component<AppProps, AppState> = StyledMaterialApp;

export function getTooltip(tip: string): any
{
  return (
    <Mui.Typography style={{ fontSize: '0.8rem', color: 'white' }}>
      {tip}
    </Mui.Typography>
  )
}

export function shortLabel(label: string): string
{
  return label.length > 4 ? label.slice(0, 4) + '..' : label;
}

// short label Element with optional tooltip, if elided
export function shortLabelOptionalTip(label: string): JSX.Element
{
  const labelP: string = shortLabel(label);
  const elided: boolean = labelP.endsWith('..');

  return elided ?
    <Mui.Tooltip title={getTooltip(label)}>
      <span>{labelP}</span>
    </Mui.Tooltip> : <span>{labelP}</span>; 
}

