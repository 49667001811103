// Core react imports
import * as React from 'react';

// Mui Imports
import * as Mui from './wrapmui';

// Public utilities
import classNames = require('classnames');

// Shared libraries
import { Util } from '@dra2020/baseclient';

// App libraries
import * as ClientActions from "../clientactions";
import * as MA from './materialapp';

export interface FeedbackViewProps
{
  actions: ClientActions.IClientActions,
  textInit: ClientActions.TextInit,

  classes?: any,
  theme?: any,
}

export interface FeedbackViewState
{
  textInit: ClientActions.TextInit,
}

function FeedbackViewStyles(theme: any): any
{
  return (MA.AppStyles(theme));
}

class InternalFeedbackView extends React.Component<FeedbackViewProps, FeedbackViewState>
{
  constructor(props: any)
  {
    super(props);

    this.handleClose = this.handleClose.bind(this);
    this.textChange = this.textChange.bind(this);
    this.state = { textInit: Util.shallowCopy(props.textInit) };
  }

  textChange(e: any): void
  {
    let {textInit} = this.state;
    textInit[e.target.name] = e.target.value;
    this.setState({ textInit: textInit });
  }

  handleClose(ok: boolean = false): void
  {
    const {actions} = this.props;
    const {textInit} = this.state;

    if (ok)
    {
      actions.fire(ClientActions.Feedback, textInit.feedback);
      actions.fire(ClientActions.Alert, { message: 'Thanks for your feedback!' });
    }

    actions.fire(ClientActions.Close, { dialogname: 'feedback' });
  }

  render(): any
  {
    const {classes, actions} = this.props;
    const {textInit} = this.state;

    return (
      <Mui.Dialog
        open={true}
        onClose={this.handleClose}
        aria-labelledby="Feedback Dialog"
      >
        <Mui.DialogTitle id="forgot-password-title">Feedback for What I Did</Mui.DialogTitle>
        <Mui.DialogContent>
          <Mui.DialogContentText>
            Thanks for any feedback on bugs, features or scenarios.
          </Mui.DialogContentText>
          <Mui.TextField
            autoFocus
            margin="dense"
            multiline
            rows="30"
            id="feedback"
            label="Feedback"
            inputProps={{name: 'feedback'}}
            onChange={this.textChange}
            type="text"
            value={textInit.feedback}
            fullWidth
            variant='standard'
          />
        </Mui.DialogContent>
        <Mui.DialogActions>
          <Mui.Button onClick={(e: any) => this.handleClose(true) } color="primary">
            Submit
            </Mui.Button>
          <Mui.Button onClick={(e: any) => this.handleClose() } color="secondary">
            Cancel
            </Mui.Button>
        </Mui.DialogActions>
      </Mui.Dialog>
    );
  }
}

let StyledFeedbackView: any = Mui.withStyles(FeedbackViewStyles, {withTheme: true})(InternalFeedbackView);
export const FeedbackView: new () => React.Component<FeedbackViewProps, {}> = StyledFeedbackView;
