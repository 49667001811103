import { Util, FSM, OT } from '@dra2020/baseclient';
import { Environment } from './env';
import * as CT from '../shared/coretypes';
import * as Ajax from './ajax';
import * as ClientActions from "./clientactions";
import * as DU from './dateutil';
import * as SD from '../shared/simpledate';
import { ColorValues, ColorList } from './colors';

const MaxRefreshFetches = 10;
const RefreshInterval = 1000 * 60 * 15;

export interface User
{
  _atomicUpdate?: number,
  id?: string,
  name?: string,
  email?: string,
  twitterhandle?: string,
  resetGUID?: string,
  password?: string,
  verified?: boolean,
}

const UserAnonymous:User  = { id: 'anonymous', name: 'anonymous', email: '', password: '' };

export type Users = { [id: string]: User }

interface CacheItem
{
  id?: string,
  _atomicUpdate?: number,
}
type Cache = { [id: string]: CacheItem };

export type Stats = Map<string, number>;  // map of eventid to count for interval requested
export type StatWithNames = { c: string, e: string, n: number };

export function accumStats(accum: Stats, s: Stats): Stats
{
  if (! accum) accum = new Map<string, number>();
  s.forEach((n: number, idEvent: string) => { accum.set(idEvent, n + (accum.get(idEvent) || 0)) });
  return accum;
}

function basicallyEqual(v1: any, v2: any): boolean
{
  if (!v1 && !v2) return true;
  return v1 === v2;
}

function copyDiffProperties(copyto: any, o1: any, o2: any, props: string[]): any
{
  props.forEach(p => {
      if (o2[p] !== undefined && (!o1 || !basicallyEqual(o2[p], o1[p])))
        copyto[p] = o2[p];
    });
  return copyto;
}

export function sortDayKey(k1: string, k2: string): number
{
  if (!k1 || !k2) return 0;
  let a1 = k1.split('.');
  let a2 = k2.split('.');
  if (a1.length != 2 || a2.length != 2) return 0;
  let d1 = Number(a1[0]);
  let m1 = Number(a1[1]);
  let d2 = Number(a2[0]);
  let m2 = Number(a2[1]);
  if (m1 < m2) return -1;
  if (m1 > m2) return 1;
  if (d1 < d2) return -1;
  if (d1 > d2) return 1;
  return 0;
}

class FsmTextData extends FSM.Fsm
{
  name: string;
  fsmGet: Ajax.FsmGetText;

  constructor(env: Environment, name: string)
  {
    super(env);
    this.name = name;
  }

  get env(): Environment { return this._env as Environment }

  tick(): void
  {
    if (this.ready && this.isDependentError)
      this.setState(FSM.FSM_ERROR);
    else if (this.ready)
    {
      switch (this.state)
      {
        case FSM.FSM_STARTING:
          this.fsmGet = new Ajax.FsmGetText(this.env, `/${this.name}.txt`);
          this.waitOn(this.fsmGet);
          this.setState(FSM.FSM_PENDING);
          break;

        case FSM.FSM_PENDING:
          if (this.fsmGet.data)
          {
            this.env.ss._textData[this.name] = this.fsmGet.data;
            this.env.actions.fire(ClientActions.Render);
          }
          this.setState(FSM.FSM_DONE);
          break;
      }
    }
  }
}

export class ServerState
{
  env: Environment;
  user: User;
  userSet: boolean;
  users: Users;
  eventCache: CT.EventCache;
  events: CT.EventIndex;
  eByc: CT.EventsByCategory;
  categoryCache: CT.CategoryCache;
  categories: CT.CategoryIndex;
  yearCache: CT.YearCache;
  yearsByYear: CT.YearCache;
  dirtyYears: CT.YearCache;
  commentCache: CT.CommentCache;
  commentsByYear: CT.CommentCache;
  dirtyComments: CT.CommentCache;
  activeRequests: { [name: string]: FSM.Fsm };
  refreshTimes: { [id: string]: number };
  anonEmail: string;
  _textData: { [name: string]: string };
  idFlush: any;

  constructor(env: Environment)
  {
    this.env = env;
    this.user = UserAnonymous;
    this.userSet = false;
    this.users = {};
    this.events = {};
    this.eByc = new Map<string, string[]>();
    this.eventCache = {};
    this.categories = {};
    this.categoryCache = {};
    this.yearCache = {};
    this.yearsByYear = {};
    this.dirtyYears = {};
    this.commentCache = {};
    this.commentsByYear = {};
    this.dirtyComments = {};
    this.activeRequests = {};
    this.refreshTimes = {};
    this.anonEmail = '';
    this._textData = {};
    this.flushYears = this.flushYears.bind(this);
    this.sortCategory = this.sortCategory.bind(this);
    this.sortEvent = this.sortEvent.bind(this);
  }

  get uid(): string { return this.user.id }
  get isAnon(): boolean { return this.uid === 'anonymous' }
  get isUnverified(): boolean { return !this.isAnon && !this.user.verified }

  logout(): void
  {
    this.user = UserAnonymous;
  }

  isRequestActive(name: string): boolean
  {
    if (this.activeRequests[name] && this.activeRequests[name].done)
      delete this.activeRequests[name];
    return !!this.activeRequests[name];
  }

  setRequestActive(name: string, fsm: FSM.Fsm): void
  {
    this.activeRequests[name] = fsm;
  }

  userName(userid?: string): string
  {
    if (! userid)
      return this.userName(this.user.id);
    let u = this.users[userid];
    return !u || !u.name ? userid : u.name;
  }

  refreshUser(): void
  {
    if (! this.isRequestActive('userview'))
      this.setRequestActive('userview', new Ajax.FsmAjax(this.env, { url: '/api/sessions/userview', data: {} }));
  }

  refreshEvents(): void
  {
    if (! this.isRequestActive('listevents'))
      this.setRequestActive('listevents', new Ajax.FsmAjax(this.env, { url: '/api/sessions/listevents', data: {} }));
  }

  refreshCategories(): void
  {
    if (! this.isRequestActive('listcategories'))
      this.setRequestActive('listcategories', new Ajax.FsmAjax(this.env, { url: '/api/sessions/listcategories', data: {} }));
  }

  refreshYears(): void
  {
    let d = new Date();
    let year = Number(d.getFullYear());
    for (let i = year-1; i <= year+1; i++)
      this.refreshYear(i);
  }

  refreshYear(year: number): void
  {
    if (! this.isRequestActive(`listyears.${year}`))
      this.setRequestActive(`listyears.${year}`,
                            new Ajax.FsmAjax(this.env, { url: '/api/sessions/listyears', data: { year: String(year)} }));
  }

  refreshComments(): void
  {
    let d = new Date();
    let year = Number(d.getFullYear());
    for (let i = year-1; i <= year+1; i++)
      this.refreshComment(i);
  }

  refreshComment(year: number): void
  {
    if (! this.isRequestActive(`listcomments.${year}`))
      this.setRequestActive(`listcomments.${year}`,
                            new Ajax.FsmAjax(this.env, { url: '/api/sessions/listcomments', data: { year: String(year)} }));
  }

  refreshList(): void
  {
    if (! this.isAnon)
    {
      this.refreshEvents();
      this.refreshCategories();
      this.refreshYears();
      this.refreshComments();
    }
  }

  updateEvent(e: CT.Event): void
  {
    let r: CT.EventRecord = { id: this.uid, events: { [e.id]: e } };
    this.setRequestActive(`updateevents.${r.id}`, new Ajax.FsmAjax(this.env, { url: '/api/sessions/updateevents', data: r }));

    // Also update locally so user sees immediate feedback
    r = this.events[this.uid];
    if (r?.events) r.events[e.id] = e;
    this.deriveEventList();
    this.env.actions.fire(ClientActions.StateUpdate);
  }

  updateCategory(c: CT.Category): void
  {
    let r: CT.CategoryRecord = { id: this.uid, categories: { [c.id]: c } };
    this.setRequestActive(`updatecategories.${r.id}`, new Ajax.FsmAjax(this.env, { url: '/api/sessions/updatecategories', data: r }));

    // Also update locally so user sees immediate feedback
    r = this.categories[this.uid];
    if (r?.categories) r.categories[c.id] = c;
    this.deriveCategoryList();
    this.env.actions.fire(ClientActions.StateUpdate);
  }

  flushYears(): void
  {
    Object.values(this.dirtyYears).forEach((r: CT.YearRecord) => {
        this.setRequestActive(`updateyears.${r.id}`, new Ajax.FsmAjax(this.env, { url: '/api/sessions/updateyears', data: r }));
      });
    this.dirtyYears = {};
    Object.values(this.dirtyComments).forEach((r: CT.CommentRecord) => {
        this.setRequestActive(`updatecomments.${r.id}`, new Ajax.FsmAjax(this.env, { url: '/api/sessions/updatecomments', data: r }));
      });
    this.dirtyComments = {};
    delete this.idFlush;
  }

  queueFlush(): void
  {
    // Reset delay to 5 seconds
    if (this.idFlush !== undefined)
      clearTimeout(this.idFlush);

    // And queue to flush in a bit
    this.idFlush = setTimeout(this.flushYears, 5000);
  }

  updateDays(year: string, days: CT.DayIndex): void
  {
    // Mark those days dirty
    let r: CT.YearRecord = { id: `${this.uid}:${year}`, year, days: days };
    let rExisting = this.dirtyYears[r.id];
    if (rExisting)
      Util.shallowAssign(rExisting.days, r.days);
    else
      this.dirtyYears[r.id] = r;
    this.queueFlush();

    // Also update locally so user sees immediate feedback
    let oldr = this.yearsByYear[year];
    if (!oldr)
      this.yearsByYear[year] = r;
    else
    {
      if (!oldr.days) oldr.days = {};
      Object.keys(days).forEach(day => { oldr.days[day] = days[day] });
    }
    this.env.actions.fire(ClientActions.StateUpdate);
  }

  updateComments(year: string, days: CT.CommentIndex): void
  {
    // Mark those days dirty
    let r: CT.CommentRecord = { id: `${this.uid}:${year}`, year, days: days };
    let rExisting = this.dirtyComments[r.id];
    if (rExisting)
      Util.shallowAssign(rExisting.days, r.days);
    else
      this.dirtyComments[r.id] = r;
    this.queueFlush();

    // Also update locally so user sees immediate feedback
    let oldr = this.commentsByYear[year];
    if (!oldr)
      this.commentsByYear[year] = r;
    else
    {
      if (!oldr.days) oldr.days = {};
      Object.keys(days).forEach(day => { oldr.days[day] = days[day] });
    }
    this.env.actions.fire(ClientActions.StateUpdate);
  }

  textData(name: string): string
  {
    if (this._textData[name])
      return this._textData[name];
    if (! this.isRequestActive(name))
      this.setRequestActive(name, new FsmTextData(this.env, name));
    return '';
  }

  initialize(): void
  {
    this.refreshUser();
    this.refreshList();
  }

  setCacheItem(cache: Cache, u: CacheItem): void
  {
    let o = cache[u.id];
    if (!o || !o._atomicUpdate || u._atomicUpdate >= o._atomicUpdate)
      cache[u.id] = u;
  }

  setCache(cache: Cache, update: Cache): void
  {
    if (update)
      Object.values(update).forEach(ci => this.setCacheItem(cache, ci));
  }

  deriveEventList(): void
  {
    this.events = {};
    Object.values(this.eventCache).forEach((r: CT.EventRecord) => {
        if (r.events) Object.values(r.events).forEach((e: CT.Event) => { if (!e.deleted) this.events[e.id] = e });
      });

    // Set up eventByCategoryList
    this.eByc.clear();
    Object.values(this.events).forEach((e: CT.Event) => {
        if (! this.eByc.has(e.idCat))
          this.eByc.set(e.idCat, []);
        this.eByc.get(e.idCat).push(e.id);
      });
  }

  get eventsByCategory(): CT.EventsByCategory
  {
    return this.eByc;
  }

  eventByName(name: string): CT.Event
  {
    name = name.trim().toLowerCase();
    return Object.values(this.events).find(
        (e: CT.Event) => e.name.toLowerCase() === name) as CT.Event;
  }

  categoryByName(name: string): CT.Category
  {
    name = name.trim().toLowerCase();
    return Object.values(this.categories).find(
        (c: CT.Category) => c.name.toLowerCase() === name) as CT.Category;
  }

  categoryNames(): string[]
  {
    return Object.values(this.categories).map((c: CT.Category) => c.name)
  }

  stats(year?: string, month?: string, accum?: Stats): Stats
  {
    if (! accum) accum = new Map<string, number>();
    if (!year)
      Object.keys(this.yearsByYear).forEach(y => { this.stats(y, undefined, accum) });
    else if (! month)
    {
      for (let m = 0; m < 12; m++)
        this.stats(year, String(m), accum);
    }
    else
    {
      let y = this.yearsByYear[year];
      if (y && y.days)
      {
        let sd = { year: Number(year), month: Number(month), day: 1, hour: 0, minute: 0 };
        let ds = SD.formatDate(sd);
        let len = DU.monthLength(ds);
        for (let d = 1; d <= len; d++)
        {
          let dayKey = `${d}.${month}`;
          let ix = y.days[dayKey];
          if (ix)
            Object.values(ix).forEach((i: CT.Instance) => {
                if (i.on && this.events[i.eventid])
                  accum.set(i.eventid, (1 + (accum.get(i.eventid) || 0)))
              });
        }
      }
    }
    return accum;
  }

  statsWithNames(stats: Stats): StatWithNames[]
  {
    let withNames: StatWithNames[] = [];
    stats.forEach((n: number, id: string) => {
        let e = this.events[id];
        if (e)
        {
          let c = this.categories[e.idCat];
          if (c)
            withNames.push({c: c.name, e: e.name, n });
        }
      });
    return withNames.sort((s1: StatWithNames, s2: StatWithNames) => {
        return s1.c < s2.c
          ? -1
          : s1.c > s2.c
            ? 1
            : s1.e < s2.e
              ? -1
              : s1.e > s1.e
                ? 1
                : 0
      });
  }

  toYearKey(ds: SD.DateString): string
  {
    let sd = SD.parseDate(ds);
    return `${sd.year}`
  }

  toDayKey(ds: SD.DateString): string
  {
    let sd = SD.parseDate(ds);
    return `${sd.day}.${sd.month}`
  }

  toDateFromKey(year: string, dayKey: string): SD.DateString
  {
    let a = dayKey.split('.');
    return SD.formatDate({ year: Number(year), month: Number(a[1]), day: Number(a[0]), hour: 0, minute: 0 })
  }

  instancesByDate(sd: SD.DateString): CT.InstanceIndex
  {
    let y = this.yearsByYear[this.toYearKey(sd)];
    if (!y || !y.days) return {};
    let ix = y.days[this.toDayKey(sd)];
    return ix || {};
  }

  instancesByKey(year: string, dayKey: string): CT.InstanceIndex
  {
    let y = this.yearsByYear[year];
    if (!y || !y.days) return {};
    return y.days[dayKey] || {};
  }

  commentByDate(sd: SD.DateString): string
  {
    let y = this.commentsByYear[this.toYearKey(sd)];
    if (!y || !y.days) return '';
    let s = y.days[this.toDayKey(sd)];
    return s || '';
  }

  commentByKey(year: string, dayKey: string): string
  {
    let y = this.commentsByYear[year];
    if (!y || !y.days) return '';
    return y.days[dayKey] || '';
  }

  nthColor(i: number): string
  {
    return ColorList[i];
  }

  allColors(): string[]
  {
    let colors: string[] = [];
    for (let i = 0; i < ColorList.length; i++)
      colors.push(this.nthColor(i));
    return colors;
  }

  nextColor(): string
  {
    let colors = Object.values(this.events).map((e: CT.Event) => e.color);
    let colorSet = new Set<string>(colors);
    for (let i = 0; i < ColorList.length; i++)
    {
      let color = this.nthColor(i);
      if (! colorSet.has(color))
        return color;
    }

    // Oops, all used, reuse random one
    return this.nthColor(Math.floor(Math.random() * ColorList.length));
  }

  deriveCategoryList(): void
  {
    this.categories = {};
    Object.values(this.categoryCache).forEach((r: CT.CategoryRecord) => {
        if (r.categories) Object.values(r.categories).forEach((c: CT.Category) => { this.categories[c.id] = c });
      });
  }

  deriveYearList(): void
  {
    this.yearsByYear = {};
    Object.values(this.yearCache).forEach((y: CT.YearRecord) => { this.yearsByYear[y.year] = y });
  }

  deriveCommentList(): void
  {
    this.commentsByYear = {};
    Object.values(this.commentCache).forEach((y: CT.CommentRecord) => { this.commentsByYear[y.year] = y });
  }

  processResult(result: any): void
  {
    // Generic error
    if (result && result.result === OT.EBadRequest && result.message)
      this.env.actions.fire(ClientActions.Alert, { message: result.message });

    // Handle cache information
    if (!result || !result.cache) return;

    // Handle user
    let newuser = false;
    if (result.cache.user)
    {
      this.userSet = true;
      newuser = (! this.user || this.user.id !== result.cache.user.id)
      this.user = result.cache.user;
      this.users[this.user.id] = this.user;
      if (this.user.verified)
        this.env.verifying = false;
    }

    // Handle list of users
    this.setCache(this.users, result.cache.users);

    // Handle list of events
    if (result.cache.events)
    {
      this.setCache(this.eventCache, result.cache.events);
      this.deriveEventList();
    }

    // Handle list of categories
    if (result.cache.categories)
    {
      this.setCache(this.categoryCache, result.cache.categories);
      this.deriveCategoryList();
    }

    // Handle list of years
    if (result.cache.years)
    {
      this.setCache(this.yearCache, result.cache.years);
      this.deriveYearList();
    }

    // Handle list of comments
    if (result.cache.comments)
    {
      this.setCache(this.commentCache, result.cache.comments);
      this.deriveCommentList();
    }

    // And notify UI of new data
    this.env.actions.fire(ClientActions.StateUpdate);
    if (newuser)
      this.refreshList();
  }

  sortCategory(id1: string, id2: string): number
  {
    let c1 = this.categories[id1];
    let c2 = this.categories[id2];
    if (!c1 || !c2) return 0;
    return (c1.name < c2.name) ? -1 : (c1.name > c2.name) ? 1 : 0;
  }

  sortEvent(id1: string, id2: string): number
  {
    let e1 = this.events[id1];
    let e2 = this.events[id2];
    if (!e1 || !e2) return 0;
    return (e1.name < e2.name) ? -1 : (e1.name > e2.name) ? 1 : 0;
  }
}
