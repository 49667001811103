// Core react imports
import * as React from 'react';

// Mui Imports
import * as Mui from './wrapmui';

// Public utilities
import classNames = require('classnames');

// Shared Libraries
import { Util } from "@dra2020/baseclient";

// App libraries
import * as ClientActions from "../clientactions";
import * as MA from './materialapp';
import * as V from './viewers';

export interface ProfileViewProps
{
  actions: ClientActions.ClientActions;
  textInit: ClientActions.TextInit,

  classes?: any;
  theme?: any;
}

export interface ProfileViewState
{
  textInit: ClientActions.TextInit,
}

function ProfileViewStyles(theme: any): any
{
  return (MA.AppStyles(theme));
}

class InternalProfileView extends React.Component<ProfileViewProps, ProfileViewState>
{
  constructor(props: any)
  {
    super(props);

    this.handleClose = this.handleClose.bind(this);
    this.textChange = this.textChange.bind(this);
    this.state = { textInit: Util.shallowCopy(props.textInit) };
  }

  textChange(e: any): void
  {
    let {textInit} = this.state;
    textInit[e.target.name] = e.target.value;
    this.setState({ textInit: textInit });
  }

  handleClose(ok: boolean): void
  {
    const {actions} = this.props;
    const {textInit} = this.state;

    if (ok)
      actions.fire(ClientActions.Profile, textInit);

    actions.fire(ClientActions.Close, { dialogname: 'profile' });
  }

  render()
  {
    const {actions} = this.props;
    const {textInit} = this.state;
    let bNoEmail: boolean = textInit.email == '';
    let bNoName: boolean = textInit.name == '';

    return (
      <Mui.Dialog
        open={true}
        onClose={() => this.handleClose(false)}
        aria-labelledby="User Profile Dialog"
      >
        <Mui.DialogTitle id="profile-dialog-title">User Profile</Mui.DialogTitle>
        <Mui.DialogContent>
          <Mui.DialogContentText>
            Your name, email and password are private to this app.
          </Mui.DialogContentText>
          <Mui.TextField
            autoFocus
            margin="dense"
            id="name"
            label="User Name"
            inputProps={{name: 'name'}}
            onChange={this.textChange}
            onKeyPress={(e: any) => { if (e.key === 'Enter' && !bNoEmail && !bNoName) this.handleClose(true) }}
            type="text"
            value={textInit.name}
            fullWidth
            variant='standard'
          />
          <Mui.TextField
            margin="dense"
            id="email"
            label="Email Address"
            inputProps={{name: 'email'}}
            onChange={this.textChange}
            onKeyPress={(e: any) => { if (e.key === 'Enter' && !bNoEmail && !bNoName) this.handleClose(true) }}
            type="email"
            value={textInit.email}
            fullWidth
            variant='standard'
          />
          <Mui.TextField
            margin="dense"
            id="password"
            label="Password"
            inputProps={{name: 'password'}}
            onChange={this.textChange}
            onKeyPress={(e: any) => { if (e.key === 'Enter' && !bNoEmail && !bNoName) this.handleClose(true) }}
            type="password"
            value={textInit.password}
            fullWidth
            variant='standard'
          />
          {/*
          <Mui.TextField
            autoFocus
            margin="dense"
            id="twitterhandle"
            label="Twitter Handle"
            inputProps={{name: 'twitterhandle'}}
            onChange={(e: any) => actions.fire(ClientActions.TextChange, e)}
            onKeyPress={(e: any) => { if (e.key === 'Enter' && !bNoEmail && !bNoName) this.handleClose(true) }}
            type="text"
            value={textInit.twitterhandle}
            fullWidth
            variant='standard'
          />*/}
        </Mui.DialogContent>
        <Mui.DialogActions>
          {/*
          <Mui.Button disabled={bNoEmail} onClick={(e: any) => actions.fire(ClientActions.VerifyEmail, e)} color="primary">
            Verify Email
          </Mui.Button>
          */}
          <Mui.Button onClick={() => this.handleClose(false)}>
            Cancel
          </Mui.Button>
          <Mui.Button disabled={bNoEmail || bNoName} onClick={() => this.handleClose(true)} color="primary">
            Update Profile
          </Mui.Button>
        </Mui.DialogActions>
      </Mui.Dialog>
    );
  }

}

let StyledProfileView: any = Mui.withStyles(ProfileViewStyles, {withTheme: true})(InternalProfileView);
export const ProfileView: new () => React.Component<ProfileViewProps, {}> = StyledProfileView;
