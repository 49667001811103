// Shared libraries
import { FSM } from "@dra2020/baseclient";


// Local libraries
import { Environment } from "./env";
//import * as TV from "./components/tableview";

let ActionID = -1;
export const NoOp = ActionID++;
export const Home = ActionID++;
export const PopView = ActionID++;
export const PushView = ActionID++; // arg is ViewStateLiteral
export const Alert: number = ActionID++;  // arg is ParamAlert
export const Progress: number = ActionID++;  // arg is ParamProgress
export const Render = ActionID++;
export const StateUpdate = ActionID++;
export const Undo = ActionID++;
export const Redo = ActionID++;
export const Hash = ActionID++; // arg is ParamHash
export const About = ActionID++;
export const Help = ActionID++;
export const Privacy = ActionID++;

// Table Actions
export const SelectionEmpty = ActionID++;
export const SelectionSet = ActionID++; // arg is sid
export const SelectionDouble = ActionID++; // arg is sid
export const SelectionClear = ActionID++; // arg is sid
export const SelectionSetAll = ActionID++;
export const SelectionMeta = ActionID++;
export const TableIconSelect = ActionID++;
export const TableCheckSelect = ActionID++;
export const TableButtonSelect = ActionID++;
export const TableSort = ActionID++;
export const SetColumn = ActionID++;

// Dialogs
export const Open = ActionID++;
export const Close = ActionID++;
export const CloseAll = ActionID++;
export const Apply = ActionID++;
export const TextChange = ActionID++;

// Menus
export const ProfileMenu = ActionID++;
export const AboutMenu = ActionID++;
export const Menu = ActionID++;
export const Popover = ActionID++; // Arg is ParamPopover

export const SetErrorMessage = ActionID++;  // arg is error string

// User login related
export const Login: number = ActionID++;
export const Signup: number = ActionID++;
export const Logout = ActionID++;
export const Profile = ActionID++;
export const Feedback = ActionID++;
export const ForgotPassword: number = ActionID++;
export const ResetPassword: number = ActionID++;
export const VerifyEmail = ActionID++;
export const OpenProfile = ActionID++;
export const OpenLogin = ActionID++;
export const OpenSignup = ActionID++;
export const SetLoginMessage = ActionID++;

// App Actions
export const Day = ActionID++;
export const Month = ActionID++;
export const Year = ActionID++;
export const Stats = ActionID++;
export const Comments = ActionID++;
export const StatsView = ActionID++;
export const ListView = ActionID++;
export const ExportStats = ActionID++;
export const ExportComments = ActionID++;
export const Events = ActionID++;
export const Event = ActionID++;
export const ToggleReadOnly = ActionID++;
export const DeleteEvent = ActionID++;
export const UpdateComment = ActionID++;
export const ToggleInstance = ActionID++;
export const Category = ActionID++;
export const Colors = ActionID++;
export const NextDate = ActionID++;
export const PrevDate = ActionID++;
export const Categories = ActionID++;
export const MouseDown = ActionID++;
export const MouseUp = ActionID++;
export const SwipedLeft = ActionID++;
export const SwipedRight = ActionID++;
export const SwipedUp = ActionID++;
export const SwipedDown = ActionID++;

export type ViewStateLiteral = 'day' | 'month' | 'year' | 'stats' | 'events' | 'comments' |
                               'event' | 'colors' | 'categories' | 'category' |
                               'about' | 'help' | 'privacy' |
                               'login' | 'signup' | 'forgot' | 'reset';

export class ViewState
{
  viewStack: ViewStateLiteral[];

  constructor()
  {
    this.initialize();
  }

  initialize(): void
  {
    this.viewStack = [ 'day' ];
  }

  push(vsl: ViewStateLiteral): void
  {
    switch (vsl)
    {
      case 'day':
      case 'month':
      case 'year':
      case 'stats':
      case 'events':
      case 'comments':
        this.viewStack = [ vsl ];
        break;
      default:
        this.viewStack.push(vsl);
        break;
    }
  }

  pop(): void
  {
    if (this.viewStack.length > 1)
      this.viewStack.pop();
  }

  get top(): ViewStateLiteral
  {
    return this.viewStack[this.viewStack.length-1];
  }

  get atTop(): boolean
  {
    return this.viewStack.length == 1;
  }
}

export type StatLiteral = 'byyear' | 'bymonth' | 'todate';
export type ListLiteral = 'grid' | 'list' | 'details';

export type TextInit = { [prop: string]: string };

export interface ParamOpen
{
  name: string,
  params?: any,
}

export interface TextBlock
{
  variant: string;  // title, subheading, body1, body2, row, link, indentBody, beginTable/EndTable, beginExpansion/endExpansion
  text?: string;
  cells?: string[];
  link?: string;    // url: only when variant = 'link'
  label?: string;   // url label: only when variant = 'link'
}

export interface TextContainer
{
  data: TextBlock[];
}

export type ParamProfile = TextInit;

export type CloseFunction = (ok: boolean) => void;
export interface ParamAlert
{
  title?: string;
  message?: string;
  ok?: string;                    // Text for OK button
  cancel?: string;                // Text for cancel button
  onClose?: CloseFunction;        // Function to close (triggered by OK, ESC, click anywhere)
  anchorOrigin?: {vertical: number | 'top' | 'center' | 'bottom', horizontal: number | 'left' | 'center' | 'right'};
}

export interface ParamPopover
{
  id: string,
  message: string,
}

export interface ParamPick
{
  alertParam: ParamAlert,
  multiple?: boolean,
  target?: string,
}

export interface ParamProgress
{
  title?: string;
  message?: string;
  onClose?: CloseFunction;        // Function to close (triggered by OK, ESC, click anywhere)
  value?: number;                 // If present, determinant progress indicator 0-100, otherwise indeterminant
}

export interface ParamTableIcon
{
  id: string,
  name: string,
  selected: boolean,
}

export interface ParamDownloadData
{
  filename: string,
  contents: string,
}

export class ActionTracker
{
  _pending: { [key: string]: { data: any, fsm: FSM.Fsm } };

  constructor()
  {
    this._pending = {};
  }

  start(key: string, data: any = true, fsm: FSM.Fsm = null): void
  {
    this._pending[key] = { data: data, fsm: fsm };
  }

  end(key: string): void
  {
    if (this._pending[key] && this._pending[key].fsm)
      this._pending[key].fsm.setState(FSM.FSM_DONE);
    delete this._pending[key];
  }

  pending(key: string): any
  {
    return this._pending[key];
  }

  ispending(key: string): boolean
  {
    return this._pending[key] !== undefined;
  }
}

export interface IClientActions
{
  env: Environment;

  fire: (a: number, arg?: any) => boolean
}

interface Mixin
{
  next: Mixin;
  actions: ClientActions;
}

export class ClientActions implements IClientActions
{
  env: Environment;
  mixins: Mixin;          // Mixins allow independent implementations of separate functionality
  parent: ClientActions;  // Parent allows a mixin to refire and access parent functionality
  tracker: ActionTracker;

  constructor(env: Environment)
  {
    this.env = env;
    this.mixins = null;
    this.parent = null;
    this.tracker = new ActionTracker();
  }

  mixin(actions: ClientActions): void
  {
    if (actions)
    {
      this.mixins = { next: this.mixins, actions: actions };
      actions.parent = this;
    }
  }

  unmix(actions: ClientActions): void
  {
    if (this.mixins && this.mixins.actions === actions)
      this.mixins = this.mixins.next;
    else
      for (let m = this.mixins; m != null && m.next != null; m = m.next)
        if (m.next.actions === actions)
        {
          m.next = m.next.next;
          break;
        }
  }

  _fire(a: number, arg?: any): boolean
  {
    // Try mixins
    for (let mixin = this.mixins; mixin; mixin = mixin.next)
      if (mixin.actions.fire(a, arg))
        return true;

    // No handler
    return false;
  }

  fire(a: number, arg?: any): boolean
  {
    return false;
  }

  upfire(a: number, arg?: any): boolean
  {
    return this.top.fire(a, arg);
  }

  get top(): ClientActions
  {
    let actions: ClientActions = this;

    while (actions.parent)
      actions = actions.parent;

    return actions;
  }

  start(key: string, data: any = null): void
  {
    this.top.tracker.start(key, data);
  }

  end(key: string): void
  {
    this.top.tracker.end(key);
  }

  pending(key: string): any
  {
    return this.top.tracker.pending(key);
  }

  ispending(key: string): any
  {
    return this.top.tracker.ispending(key);
  }
}
