// Core react imports
import * as React from 'react';

// Mui Imports
import * as Mui from './wrapmui';

// Public utilities
import classNames = require('classnames');

// Shared Libraries
import { Util } from '@dra2020/baseclient';

// App libraries
import { Environment } from '../env';
import * as ClientActions from "../clientactions";
import * as MA from './materialapp';

import { ProfileView } from './profileview';
import { AlertView } from './alertview';
import { ProgressView } from './progressview';
import { FeedbackView } from './feedbackview';

export interface DialogProps
{
  dialogname: string,
  open: boolean,
  textInit: ClientActions.TextInit,
}

export type DialogIndex = { [dialogname: string]: DialogProps };

let dialogIndex: DialogIndex = {
  profile: {
      dialogname: 'profile',
      open: false,
      textInit: {},
    },
  alert: {
      dialogname: 'alert',
      open: false,
      textInit: {},
    },
  progress: {
      dialogname: 'progress',
      open: false,
      textInit: {},
    },
  feedback: {
      dialogname: 'feedback',
      open: false,
      textInit: {},
    },
}

export class DialogActions extends ClientActions.ClientActions
{
  constructor(env: Environment)
  {
    super(env)
  }

  fire(id: number, arg?: any): boolean
  {
    let handled = true;

    switch (id)
    {
      default:
        handled = false;
        break;

      case ClientActions.Open:
        dialogIndex[arg.dialogname].open = true;
        Util.shallowAssign(dialogIndex[arg.dialogname].textInit, arg.textInit);
        this.upfire(ClientActions.Render);
        break;

      case ClientActions.Close:
        dialogIndex[arg.dialogname].open = false;
        this.upfire(ClientActions.Render);
        break;

      case ClientActions.CloseAll:
        Object.values(dialogIndex).forEach((p: DialogProps) => { p.open = false });
        this.upfire(ClientActions.Render);
    }

    return handled;
  }
}

//
// Overview:
//    Properties associated with viewers can in general be just part of "props" and flow
//    through to the appropriate viewer.
//    The exception is text fields which need to be associated with viewer "state" to get
//    updates and cursor position to be handled correctly.
//    So:
//      1. Each viewer has a name, an 'open' flag, and a set of text state properties.
//      2. When a viewer is 'opened', the flag is set to true and the text properties are initialized (from some props).
//      3. When rendering, if a viewer is not open, null is returned. This ensures that if a viewer does render,
//         its constructor is called. The internal state of the viewer is initialized from these text state variables
//         at that point.
//      

export interface ViewerProps
{
  actions: ClientActions.ClientActions;
  alertState: ClientActions.ParamAlert;
  progressState: ClientActions.ParamProgress,

  classes?: any;
  theme?: any;
}

function ViewerStyles(theme: any): any
{
  return (MA.AppStyles(theme));
}

class InternalViewer extends React.Component<ViewerProps, {}>
{
  constructor(props: any)
  {
    super(props);
  }

  renderProfile(): any
  {
    const {actions} = this.props;
    const p = dialogIndex['profile'];

    return (<ProfileView actions={actions} textInit={p.textInit} />);
  }

  renderAlert(): any
  {
    const {actions, alertState} = this.props;

    return (<AlertView actions={actions} alertState={alertState}/>);
  }

  renderProgress(): any
  {
    const {actions, progressState} = this.props;

    return (<ProgressView actions={actions} progressState={progressState}/>);
  }

  renderFeedback(): any
  {
    const {actions} = this.props;
    const p = dialogIndex['feedback'];

    return (<FeedbackView actions={actions} textInit={p.textInit}/>);
  }

  render(): any
  {
    let viewers: any[] = [];

    Object.values(dialogIndex).forEach((p: DialogProps) => {
        if (p.open)
          switch (p.dialogname)
          {
            case 'profile':
              viewers.push(this.renderProfile());
              break;
            case 'alert':
              viewers.push(this.renderAlert());
              break;
            case 'progress':
              viewers.push(this.renderProgress());
              break;
            case 'feedback':
              viewers.push(this.renderFeedback());
              break;
          }
      });

    return (<div>{viewers}</div>);
  }

}

let StyledViewer: any = Mui.withStyles(ViewerStyles, {withTheme: true})(InternalViewer);
export const Viewer: new () => React.Component<ViewerProps, {}> = StyledViewer;
