import * as SD from '../shared/simpledate';

export const msMinute = 1000 * 60;
export const msHour = msMinute * 60;
export const msDay = msHour * 24;
export const msWeek = msDay * 7;

export const Months =
  ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December' ];
export const ShortMonths =
  ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec' ];
export const InitialMonths =
  ['J', 'F', 'M', 'A', 'M', 'J', 'J', 'A', 'S', 'O', 'N', 'D' ];
export const DaysOfWeek =
  ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];
export const DaysOfWeekCapital =
  ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

// Date functions

export function toDisplayHour(h: number): string
{
  if (h == 0)
    h = 12;
  else if (h > 12)
    h -= 12;
  return String(h)
}

export function toDisplayMinute(m: number): string
{
  return !m ? '00' : String(m)
}

export function hourToDisplayMeridian(h: number): string
{
  return h < 12 ? 'AM' : 'PM'
}

export function toDisplayTime(sd: SD.SimpleDate): string
{
  return `${toDisplayHour(sd.hour)}:${toDisplayMinute(sd.minute)} ${hourToDisplayMeridian(sd.hour)}`
}

export function toDayStart(s: SD.DateString): SD.DateString
{
  let sd = SD.parseDate(s);
  sd.hour = 0;
  sd.minute = 0;
  return SD.formatDate(sd);
}

export function toWeekStart(s: SD.DateString): SD.DateString
{
  let d = SD.toDate(SD.parseDate(s));
  d.setHours(0);  // get past DST changeover complexity
  d.setMinutes(0);
  d.setSeconds(0);
  d.setMilliseconds(0);
  d.setDate(d.getDate() - d.getDay());
  let sd = SD.fromDate(d);
  sd.hour = 0;    // should be already
  sd.minute = 0;  // should be already
  return SD.formatDate(sd);
}

export function monthLength(s: SD.DateString): number
{
  let sd = SD.parseDate(s);
  sd.day = 1;
  s = SD.formatDate(sd);
  let curMonth = sd.month;
  let len = 0;
  do
  {
    len++;
    s = addDays(s, 1);
    sd = SD.parseDate(s);
  } while (sd.month == curMonth);
  return len;
}

export function addDays(s: SD.DateString, deltaDay: number): SD.DateString
{
  // DST complicates this because day might be longer than 24 hours. If adding days to start-of-day (hour == 0),
  // then add some additional hours to the delta to move past any DST craziness (since the additional delta
  // won't move us into another day) and then correct for it at the end.
  let sd = SD.parseDate(s);
  let msDelta = (deltaDay * msDay) + (sd.hour == 0 ? (msHour*4) : 0);
  let d = SD.toDate(SD.parseDate(s));
  d.setTime(d.getTime() + msDelta);
  let sdAdd = SD.fromDate(d);
  if (sd.hour == 0) sdAdd.hour = 0;
  return SD.formatDate(sdAdd);
}

export function addMonths(s: SD.DateString, deltaMonth: number): SD.DateString
{
  let sd = SD.parseDate(s);
  sd.month += deltaMonth;
  if (sd.month < 0)
  {
    sd.month = 12 + sd.month;
    sd.year--;
  }
  else if (sd.month > 11)
  {
    sd.month -= 12;
    sd.year++;
  }
  return SD.formatDate(sd);
}

export function addYears(s: SD.DateString, deltaYear: number): SD.DateString
{
  let sd = SD.parseDate(s);
  sd.year += deltaYear;
  return SD.formatDate(sd);
}

export function addMinutes(s: SD.DateString, deltaMin: number): SD.DateString
{
  let d = SD.toDate(SD.parseDate(s));
  d.setTime(d.getTime() + (deltaMin * msMinute));
  return SD.formatDate(SD.fromDate(d));
}

export function toMs(d: string): number { return (new Date(d)).getTime() }

export function toLongMonth(s: SD.DateString): string
{
  let sd = SD.parseDate(s);
  return `${Months[sd.month]}, ${sd.year}`;
}

export function toLongDate(s: SD.DateString): string
{
  let sd = SD.parseDate(s);
  return `${DaysOfWeekCapital[sd.dayofweek]} ${Months[sd.month]} ${sd.day}, ${sd.year}`;
}

export function toMediumDate(s: SD.DateString): string
{
  let sd = SD.parseDate(s);
  return `${DaysOfWeekCapital[sd.dayofweek]} ${Months[sd.month]} ${sd.day}`;
}

export function toMonthDate(s: SD.DateString): string
{
  let sd = SD.parseDate(s);
  return `${DaysOfWeekCapital[sd.dayofweek]} ${ShortMonths[sd.month]} ${sd.day}`;
}

export function toShortDate(s: SD.DateString): string
{
  let sd = SD.parseDate(s);
  return `${Months[sd.month]} ${sd.day}`;
}

export function msNow(): number
{
  return (new Date()).getTime();
}
