// Core react imports
import * as React from 'react';

// Mui Imports
import * as Mui from './wrapmui';
import * as Icons from './wrapicons';

// Public utilities
import classNames = require('classnames');

// Shared Libraries
import { Util } from "@dra2020/baseclient";

// App libraries
import * as ClientActions from "../clientactions";
import * as MA from './materialapp';
import * as V from './viewers';
import * as CT from '../../shared/coretypes';

export interface LoginViewProps
{
  actions: ClientActions.ClientActions;
  textInit: ClientActions.TextInit,

  classes?: any;
  theme?: any;
}

export interface LoginViewState
{
  textInit: ClientActions.TextInit,
}

function LoginViewStyles(theme: any): any
{
  return (MA.AppStyles(theme));
}

class InternalLoginView extends React.Component<LoginViewProps, LoginViewState>
{
  constructor(props: any)
  {
    super(props);

    this.handleClose = this.handleClose.bind(this);
    this.handleForgot = this.handleForgot.bind(this);
    this.textChange = this.textChange.bind(this);
    this.state = { textInit: Util.shallowCopy(props.textInit) };
  }

  textChange(e: any): void
  {
    let {textInit} = this.state;
    textInit[e.target.name] = e.target.value;
    this.setState({ textInit: textInit });
  }

  handleClose(ok: boolean): void
  {
    const {actions} = this.props;
    const {textInit} = this.state;

    if (ok)
      actions.fire(textInit.signup ? ClientActions.Signup : ClientActions.Login, textInit);

    actions.fire(ClientActions.PopView);
  }

  handleForgot(): void
  {
    const {actions} = this.props;

    actions.fire(ClientActions.PopView);
    actions.fire(ClientActions.PushView, 'forgot');
  }

  renderTextFields(): any
  {
    const {classes, actions} = this.props;
    const {textInit} = this.state;

    let bNoEmail = CT.parseEmail(textInit.email) == null;
    let bNoName = textInit.signup && (!textInit.name || !textInit.name.trim());

    return (
        <div>
          {textInit.signup
           ? <div className={classes.spreadRow}>
             <div className={classes.spreadRow} style={{ minWidth: '150px', flexGrow: 1 }}>
              <Mui.TextField
                className={classes.mainTextField}
                required
                autoFocus
                margin='dense'
                id='name'
                label='Name'
                inputProps={{name: 'name'}}
                onChange={this.textChange}
                type='text'
                value={textInit.name}
                variant='standard'
              />
             </div>
           </div>
          :null}
          <div className={classes.spreadRow} style={{ minWidth: '150px', flexGrow: 1 }}>
            <Mui.TextField
              className={classes.mainTextField}
              required
              margin='dense'
              id='email'
              label='Email'
              inputProps={{name: 'email'}}
              onChange={this.textChange}
              type='text'
              value={textInit.email}
              variant='standard'
            />
          </div>
          <div className={classes.spreadRow} style={{ minWidth: '150px', flexGrow: 1 }}>
            <Mui.TextField
              className={classes.mainTextField}
              required
              margin='dense'
              id='password'
              label='Password'
              inputProps={{name: 'password'}}
              onChange={this.textChange}
              onKeyPress={(e: any) => { if (e.key === 'Enter' && !bNoEmail && !bNoName) this.handleClose(true) }}
              type='password'
              value={textInit.password}
              variant='standard'
            />
          </div>
        </div>
      );
  }

  renderDone(): any
  {
    const {classes, actions} = this.props;
    const {textInit} = this.state;
    let bNoEmail = CT.parseEmail(textInit.email) == null;
    let bNoName = textInit.signup && (!textInit.name || !textInit.name.trim());
    let submit = textInit.signup ? 'Sign Up' : 'Log In';
    let message = textInit.signup
      ? 'Your name, email and password are private.'
      : 'Login to What I Did';

    return (
        <div className={classes.buttonRow}>
          <div className={classes.growAble}></div>

          <Mui.Button variant='outlined' onClick={(e: any) => { this.handleClose(false) }}>
            Cancel
          </Mui.Button>
          &nbsp;
          &nbsp;
          <Mui.Button variant='outlined' onClick={(e: any) => { this.handleClose(true) }} color='primary'>
            {submit}
          </Mui.Button>
        </div>
      );
  }

  render(): any
  {
    const {classes, actions} = this.props;
    const {textInit} = this.state;

    return (
      <div  id={'login'} className={classes.mainCanvasWrapper}>
        <div className={classes.mainDialogInner}>
          <div className={classNames(classes.fontMedium)}>
            <Mui.Tooltip title={MA.getTooltip('Return to event list')}>
              <Mui.IconButton
                color='inherit'
                aria-label='back'
                onClick={() => actions.fire(ClientActions.PopView)}
                size='small'
               >
                <Icons.Clear />
               </Mui.IconButton>
            </Mui.Tooltip>
          </div>
          {this.renderTextFields()}
          <Mui.Typography className={classNames(classes.fontSmall, classes.linkText)} onClick={this.handleForgot}>
            Forgot password?
          </Mui.Typography>
          {this.renderDone()}
        </div>
      </div>
    );
  }
}

let StyledLoginView: any = Mui.withStyles(LoginViewStyles, {withTheme: true})(InternalLoginView);
export const LoginView: new () => React.Component<LoginViewProps, {}> = StyledLoginView;
