// Core react imports
import * as React from 'react';

// Mui Imports
import * as Mui from './wrapmui';
import * as Icons from './wrapicons';

// Public utilities
import classNames = require('classnames');

// Shared libraries
import { Util } from '@dra2020/baseclient';

// App libraries
import * as ClientActions from "../clientactions";
import * as MA from './materialapp';

export interface ResetViewProps
{
  actions: ClientActions.IClientActions,
  textInit: ClientActions.TextInit,

  classes?: any,
  theme?: any,
}

export interface ResetViewState
{
  textInit: ClientActions.TextInit,
}

function ResetViewStyles(theme: any): any
{
  return (MA.AppStyles(theme));
}

class InternalResetView extends React.Component<ResetViewProps, ResetViewState>
{
  constructor(props: any)
  {
    super(props);

    this.handleClose = this.handleClose.bind(this);
    this.textChange = this.textChange.bind(this);
    this.state = { textInit: Util.shallowCopy(props.textInit) };
  }

  textChange(e: any): void
  {
    let {textInit} = this.state;
    textInit[e.target.name] = e.target.value;
    this.setState({ textInit: textInit });
  }

  handleClose(ok: boolean = false): void
  {
    const {actions} = this.props;
    const {textInit} = this.state;

    if (ok)
    {
      actions.fire(ClientActions.ResetPassword, textInit);
      actions.fire(ClientActions.SetLoginMessage, 'Now login with your new password.');
    }

    actions.fire(ClientActions.PopView);
  }

  renderTextFields(): any
  {
    const {classes, actions} = this.props;
    const {textInit} = this.state;

    return (
        <div>
          <div className={classes.spreadRow} style={{ minWidth: '150px', flexGrow: 1 }}>
            <Mui.TextField
              className={classes.mainTextField}
              required
              margin='dense'
              id='password'
              label='Password'
              inputProps={{name: 'password'}}
              onChange={this.textChange}
              type='password'
              value={textInit.password}
              variant='standard'
            />
          </div>
        </div>
      );
  }

  renderDone(): any
  {
    const {classes} = this.props;

    return (
        <div className={classes.buttonRow}>
          <div className={classes.growAble}></div>
            <Mui.Button variant='outlined' onClick={(e: any) => { this.handleClose(false) }}>
              Cancel
            </Mui.Button>
            &nbsp;
            &nbsp;
            <Mui.Button variant='outlined' onClick={(e: any) => { this.handleClose(true) }} color='primary'>
              Submit
            </Mui.Button>
        </div>
    );
  }

  render(): any
  {
    const {classes, actions} = this.props;
    const {textInit} = this.state;
    let message: string = "Please enter your new password.";

    return (
      <div  id={'forgot'} className={classes.mainCanvasWrapper}>
        <div className={classes.mainDialogInner}>
          <div className={classNames(classes.fontMedium)}>
            <Mui.Tooltip title={MA.getTooltip('Return to home page')}>
              <Mui.IconButton
                color='inherit'
                aria-label='back'
                onClick={() => actions.fire(ClientActions.PopView)}
                size='small'
               >
                <Icons.Clear />
               </Mui.IconButton>
            </Mui.Tooltip>
          </div>
          <Mui.DialogContentText>
            {message}
          </Mui.DialogContentText>
          {this.renderTextFields()}
          {this.renderDone()}
        </div>
      </div>
    );
  }
}

let StyledResetView: any = Mui.withStyles(ResetViewStyles, {withTheme: true})(InternalResetView);
export const ResetView: new () => React.Component<ResetViewProps, {}> = StyledResetView;
