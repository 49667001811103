// Public libraries
import * as $ from "jquery";
import * as React from "react";
import * as ReactDOM from "react-dom";
import * as FS from "file-saver";

// Shared libraries
import { Util, FSM, Poly, G, OT, FilterExpr, LogAbstract } from "@dra2020/baseclient";

// App
import { Environment, create } from './env';
import * as ClientActions from "./clientactions";
import * as AA from "./accountactions";
import * as MA from "./components/materialapp";
import * as Mui from "./components/wrapmui";
import * as Viewers from './components/viewers';
import * as Hash from './hash';
import * as Ajax from "./ajax";
import { FsmReadFile, FsmReadJSONFiles } from './readfile';
import * as CT from '../shared/coretypes';
import * as SD from '../shared/simpledate';
import * as SS from './serverstate';
import { EventFilter } from './eventfilter';
import * as DU from './dateutil';

let coder: Util.Coder = { encoder: new TextEncoder(), decoder: new TextDecoder('utf-8') };

class Actions extends ClientActions.ClientActions
{
  app: App;

  constructor(app: App)
  {
    super(app.env);
    this.app = app;

    this.mixin(new AA.AccountActions(app.env));
    this.mixin(new Viewers.DialogActions(app.env));
  }

  fire(id: number, arg?: any): boolean
  {
    let handled: boolean = true;

    switch (id)
    {
      default:
        handled = false;
        break;

      case ClientActions.Render:
        this.app.forceRender();
        break;

      case ClientActions.StateUpdate:
        this.app.stateUpdate();
        break;

      case ClientActions.Home:
        this.app.actionHome();
        break;

      case ClientActions.PopView:
        this.app.actionPopView();
        break;

      case ClientActions.PushView:
        this.app.actionPushView(arg as ClientActions.ViewStateLiteral);
        break;

      case ClientActions.Help:
        this.app.actionHelp();
        break;

      case ClientActions.About:
        this.app.actionAbout();
        break;

      case ClientActions.Privacy:
        this.app.actionPrivacy();
        break;

      case ClientActions.Apply:
        this.app.actionApply(arg);
        break;

      case ClientActions.ProfileMenu:
        this.app.actionMenu('profile');
        break;

      case ClientActions.AboutMenu:
        this.app.actionMenu('about');
        break;

      case ClientActions.Menu:
        this.app.actionMenu(arg as string);
        break;

      case ClientActions.Popover:
        this.app.actionPopover(arg as ClientActions.ParamPopover);
        break;

      case ClientActions.OpenProfile:
        this.app.actionOpenProfile();
        break;

      case ClientActions.OpenLogin:
        this.app.actionOpenLogin();
        break;

      case ClientActions.OpenSignup:
        this.app.actionOpenSignup();
        break;

      case ClientActions.SetLoginMessage:
        this.app.actionSetLoginMessage(arg as string);
        break;

      case ClientActions.Alert:
        this.app.actionAlert(arg as ClientActions.ParamAlert);
        break;

      case ClientActions.Progress:
        this.app.actionProgress(arg as ClientActions.ParamProgress);
        break;

      case ClientActions.MouseDown:
        this.app.actionMouseDown();
        break;

      case ClientActions.MouseUp:
        this.app.actionMouseUp();
        break;

      case ClientActions.SwipedLeft:
        this.app.actionSwipedLeft();
        break;

      case ClientActions.SwipedRight:
        this.app.actionSwipedRight();
        break;

      case ClientActions.SwipedUp:
        this.app.actionSwipedUp();
        break;

      case ClientActions.SwipedDown:
        this.app.actionSwipedDown();
        break;

      case ClientActions.Day:
        this.app.actionDay(arg as string);
        break;

      case ClientActions.Month:
        this.app.actionMonth();
        break;

      case ClientActions.Year:
        this.app.actionYear();
        break;

      case ClientActions.Events:
        this.app.actionEvents();
        break;

      case ClientActions.Event:
        this.app.actionEvent(arg as string);
        break;

      case ClientActions.ToggleReadOnly:
        this.app.actionToggleReadOnly();
        break;

      case ClientActions.DeleteEvent:
        this.app.actionDeleteEvent(arg as string);
        break;

      case ClientActions.UpdateComment:
        this.app.actionUpdateComment(arg as string);
        break;

      case ClientActions.ToggleInstance:
        this.app.actionToggleInstance(arg as string);
        break;

      case ClientActions.Category:
        this.app.actionCategory(arg as string);
        break;

      case ClientActions.Colors:
        this.app.actionColors();
        break;

      case ClientActions.Stats:
        this.app.actionStats();
        break;

      case ClientActions.Comments:
        this.app.actionComments();
        break;

      case ClientActions.ExportComments:
        this.app.actionExportComments();
        break;

      case ClientActions.StatsView:
        this.app.actionStatsView(arg as ClientActions.StatLiteral);
        break;

      case ClientActions.ListView:
        this.app.actionListView(arg as ClientActions.ListLiteral);
        break;

      case ClientActions.ExportStats:
        this.app.actionExportStats();
        break;

      case ClientActions.NextDate:
        this.app.actionNextDate();
        break;

      case ClientActions.PrevDate:
        this.app.actionPrevDate();
        break;

    }

    return handled ? true : this._fire(id, arg);
  }
}

class App
{
  env: Environment;
  props: MA.AppProps;

  // For rendering
  bRender: boolean;

  // Refresh user
  bRefreshUser: boolean;

  // Actions
  actions: Actions;

  // constructor
  constructor()
  {
    this.env = create();

    this.render = this.render.bind(this);
    this.forceRender = this.forceRender.bind(this);
    this.handleUrl = this.handleUrl.bind(this);
    this.bRender = false;
    this.bRefreshUser = false;

    // Bind so I can use as generic callbacks
    this.actions = new Actions(this);
    this.env.actions = this.actions;

    // Keyboard events
    this.handleKeyDown = this.handleKeyDown.bind(this);

    // Initialize props
    this.props = {
      env: this.env,
      title: 'What I Did',
      actions: this.actions,

      // Dialogs
      alertState: {},
      progressState: {},

      // Menus
      elProfileMenuOn: null,

      // Content
      selection: new Util.CountedHash(),

      // App state
      viewState: new ClientActions.ViewState(),
      dateString: SD.nowString(),
      eventEdit: null,
      readonly: false,
      today: true,
      categoryEdit: null,
      designSize: MA.DW.WIDEST,
      designWidth: 382,
      statView: 'bymonth',
      listView: 'grid',
      isTouch: ('ontouchstart' in window),
      eventFilter: new EventFilter(this.env),
    };

    this.handleResize = this.handleResize.bind(this);
    window.addEventListener('resize', this.handleResize);
    //document.addEventListener('keydown', this.handleKeyDown);
    setTimeout(this.handleUrl, 0);
    this.handleMouse();

    // Check refresh at 1 minute intervals
    setInterval(() => { this.env.ss.refreshUser() }, 1000 * 60);
  }

  handleUrl(): void
  {
    let h = document.location.hash;
    let p = document.location.pathname;
    let re = /^#(day|help|about|privacy|login)(::)?(.*)$/;
    let a = re.exec(h);
    if (a)
    {
      let cmd = a[1];
      let id = a.length == 4 ? a[3] : '';
      switch (a[1])
      {
        case 'day':
          this.actionDay(id);
          break;
        case 'help':
          this.actionHelp();
          break;
        case 'about':
          this.actionAbout();
          break;
        case 'privacy':
          this.actionPrivacy();
          break;
        case 'login':
          this.actionHome();
          this.actionOpenLogin();
          break;
        default:
          this.actionHome();
          break;
      }
    } 
    else
    {
      let rereset = /^\/forgotpassword\/reset\/(.*)$/;
      a = rereset.exec(p);
      if (a && a.length == 2)
      {
        this.props.resetGUID = a[1];
        this.actions.fire(ClientActions.PushView, 'reset');
      }
      else
        this.actionHome();
    }
  }

  handleMouse(): void
  {
    document.addEventListener('mousedown', () => { this.actionMouseDown()});
    document.addEventListener('mouseup', () => { this.actionMouseUp() });
    document.addEventListener('touchstart', () => { this.actionMouseDown()});
    document.addEventListener('touchend', () => { this.actionMouseUp() });
    document.addEventListener('touchcancel', () => { this.actionMouseUp() });
  }

  handleResize(e: any): void
  {
    this.updateDesignSize();
  }

  handleKeyDown(e: any): boolean
  {
    let accelerators = [
      { key: 'z', action: ClientActions.Undo },
      { key: 'y', action: ClientActions.Redo },
    ];

    const { actions } = this.props;

    let key = e.key;
    for (let i: number = 0; i < accelerators.length; i++)
    {
      if (key == accelerators[i].key)
      {
        actions.fire(accelerators[i].action);
        e.preventDefault();
        e.stopPropagation();
        return false;
      }
    }

    return true;
  }

  newViewState(): void
  {
    let vsl = this.props.viewState.top;
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    document.body.scrollLeft = document.documentElement.scrollLeft = 0;
    this.forceRender();

    // Setup url bar
    if (vsl === 'about' || vsl === 'help' || vsl === 'privacy')
      window.history.replaceState(null, null, `#${vsl}`);
    else
      window.history.replaceState(null, null, `#${vsl}`);
  }

  pushViewState(vsl: ClientActions.ViewStateLiteral): void
  {
    if (vsl !== this.props.viewState.top)
      this.props.viewState.push(vsl);
    this.newViewState();
  }

  popViewState(): void
  {
    this.props.viewState.pop();
    this.newViewState();
  }

  render(): void
  {
    // Make sure design size is correct
    this.updateDesignSize();

    ReactDOM.render(
        <Mui.StyledEngineProvider injectFirst>
          <Mui.ThemeProvider theme={MA.MaterialTheme}>
            <MA.MaterialApp {...this.props} />
          </Mui.ThemeProvider>
        </Mui.StyledEngineProvider>
        , document.getElementById('root'));

    this.bRender = false;
  }

  updateDerivedDate(): void
  {
    const {dateString} = this.props;

    let year = this.env.ss.toYearKey(dateString);
    let dayKey = this.env.ss.toDayKey(dateString);
    let comment = this.env.ss.commentByKey(year, dayKey);
    this.props.actions.fire(MA.SetCommentText, comment);
  }

  updateDerived(): void
  {
    // Try refresh regularly
    if (this.env.verifying)
      this.refreshUser();
    this.updateDerivedDate();
  }

  forceRender(): void
  {
    if (!this.bRender)
    {
      this.bRender = true;
      setTimeout(theApp.render, 1);
    }
  }

  refreshUser(): void
  {
    if (!this.bRefreshUser)
    {
      this.bRefreshUser = true;
      setTimeout(() => { this.env.ss.refreshUser(); this.bRefreshUser = false }, 15000);
    }
  }

  stateUpdate(): void
  {
    this.updateDerived();
    this.forceRender();
  }

  initialize(): void
  {
    window.addEventListener('hashchange', (ev: HashChangeEvent) => {
      const { location } = window;
  
      //console.log('Hash change:' + location.hash);
      this.handleHashChange(location);
    });
    this.forceRender();
  }

  handleHashChange(location: Location): void
  {
    this.handleHashChangePart(location.hash);
  }

  handleHashChangePart(hashpart: string)
  {
  }

  clearOpenDialogs(): void
  {
    this.props.actions.fire(ClientActions.CloseAll);
  }

  updateDesignSize(): void
  {
    const {isTouch} = this.props;

    let el: any = document.getElementById('root');
    let w: number = el.clientWidth;
    let h: number = el.clientHeight;

    let designSize: MA.DW;
    if (w < 376)       designSize = MA.DW.PHONE;
    else if (w < 475)  designSize = MA.DW.PHONEPLUS;
    else if (w < 575)  designSize = MA.DW.NARROW;
    else if (w < 645)  designSize = MA.DW.NARROWPLUS;
    else if (w < 725)  designSize = MA.DW.NARROWPLUS2;
    else if (w < 770)  designSize = MA.DW.TABLET;
    else if (w < 870)  designSize = MA.DW.MEDIUM;
    else if (w < 930)  designSize = MA.DW.MEDIUMPLUS;
    else if (w < 1155) designSize = MA.DW.WIDE;
    else if (w < 1250) designSize = MA.DW.WIDER;
    else               designSize = MA.DW.WIDEST;

    // 640 is max view canvas area
    // 34 is size of left/right icons on non-touchable screens
    // 8 is left+right pixel padding in viewCanvas
    let maxDesignWidth = 640 - 8;
    if (!isTouch && designSize > MA.DW.PHONEPLUS) maxDesignWidth -= 34 * 2; // no left/right arrows when too narrow
    let designWidth = w - 8;
    if (!isTouch && designSize > MA.DW.PHONEPLUS) designWidth -= 34 * 2;
    designWidth = Math.min(maxDesignWidth, designWidth);

    if (designSize !== this.props.designSize || designWidth !== this.props.designWidth)
    {
      this.props.designSize = designSize;
      this.props.designWidth = designWidth;
      this.forceRender();
    }
  }

  actionApply(arg: any): void
  {
    const { name, props, state } = arg;

    switch (name)
    {
    }
  }

  actionMenu(menuname: string): void
  {
    let fields = menuname.split('.');
    let menu = fields[0];
    switch (menu)
    {
      case 'profile':
        this.props.elProfileMenuOn = this.props.elProfileMenuOn ? null : document.getElementById('profileMenu');
        break;
      case 'about':
        this.props.elAboutMenuOn = this.props.elAboutMenuOn ? null : document.getElementById('aboutMenu');
        break;
    }
    this.forceRender();
  }

  actionPopover(param: ClientActions.ParamPopover): void
  {
    this.props.elPopoverOn = document.getElementById(param.id);
    this.props.popoverState = param;
    this.forceRender();
    if (this.props.elPopoverOn)
      setTimeout(() => { this.props.elPopoverOn = null; this.forceRender() }, 1500);
  }

  actionOpenProfile(): void
  {
    const {actions} = this.props;

    actions.fire(ClientActions.Open, { dialogname: 'profile', textInit: this.env.ss.user });
  }

  actionOpenLogin(): void
  {
    const {actions} = this.props;

    this.actionPushView('login');
  }

  actionOpenSignup(): void
  {
    const {actions} = this.props;

    this.actionPushView('signup');
  }

  actionSetLoginMessage(message: string): void
  {
    this.props.loginMessage = message;
    this.forceRender();
  }

  actionAlert(alertState: ClientActions.ParamAlert): void
  {
    this.props.alertState = Util.shallowCopy(alertState);
    this.props.actions.fire(ClientActions.Open, { dialogname: 'alert' });
  }

  actionProgress(progressState: ClientActions.ParamProgress): void
  {
    this.props.progressState = Util.shallowCopy(progressState);
    this.props.actions.fire(ClientActions.Open, { dialogname: 'progress' });
  }

  actionDownloadData(param: ClientActions.ParamDownloadData): void
  {
    // adapted from https://ourcodeworld.com/articles/read/189/how-to-create-a-file-and-generate-a-download-with-javascript-in-the-browser-without-a-server
    let element = document.createElement('a');
    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(param.contents));
    element.setAttribute('download', param.filename);

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  }

  actionHelp(): void
  {
    this.pushViewState('help');
  }

  actionAbout(): void
  {
    this.pushViewState('about');
  }

  actionPrivacy(): void
  {
    this.pushViewState('privacy');
  }

  actionHome(): void
  {
    this.actionDay();
  }

  actionPopView(): void
  {
    this.popViewState();
  }

  actionPushView(vsl: ClientActions.ViewStateLiteral): void
  {
    this.pushViewState(vsl);
  }

  actionMouseDown(): void
  {
    this.env.mousedown = true;
  }

  actionMouseUp(): void
  {
    this.env.mousedown = false;
  }

  actionSwipedLeft(): void
  {
    let top = this.props.viewState.top;

    if (top === 'day' || top === 'month' || top === 'year' || top === 'stats')
      this.actionNextDate();
  }

  actionSwipedRight(): void
  {
    let top = this.props.viewState.top;

    if (top === 'day' || top === 'month' || top === 'year' || top === 'stats')
      this.actionPrevDate();
  }

  actionSwipedUp(): void
  {
  }

  actionSwipedDown(): void
  {
    /*
     * A little too aggressive/unexpected
     *
    let top = this.props.viewState.top;

    if (top === 'day')
      this.actionMonth();
    else if (top === 'month')
      this.actionYear();
    */
  }

  isToday(): boolean
  {
    const {dateString} = this.props;
    const today = SD.nowString();
    const sdToday = SD.parseDate(today);
    const sdDay = SD.parseDate(dateString);
    return sdToday.year === sdDay.year && sdToday.month === sdDay.month && sdToday.day === sdDay.day
  }

  actionSetDate(dateString?: SD.DateString): void
  {
    if (! dateString) dateString = SD.nowString();
    this.props.dateString = dateString;
    this.props.today = this.isToday();
    this.props.readonly = ! this.props.today;
    this.updateDerivedDate();
  }

  actionDay(dateString?: SD.DateString): void
  {
    this.pushViewState('day');
    this.actionSetDate(dateString);
  }

  actionMonth(): void
  {
    this.pushViewState('month');
  }

  actionYear(): void
  {
    this.pushViewState('year');
  }

  actionStats(): void
  {
    this.pushViewState('stats');
  }

  actionStatsView(v: ClientActions.StatLiteral): void
  {
    if (v !== this.props.statView)
    {
      this.props.statView = v;
      this.forceRender();
    }
  }

  actionComments(): void
  {
    this.pushViewState('comments');
  }

  actionExportComments(): void
  {
    let rows: string[] = [ 'Date,Comment' ];
    Object.values(this.env.ss.commentCache).forEach((c: CT.CommentRecord) => {
        if (c.days) Object.keys(c.days).forEach((dayKey: string) => {
            let s = c.days[dayKey];
            let dayMonth = dayKey.split('.');
            let date = `${Number(dayMonth[1])+1}/${dayMonth[0]}/${c.year}`;
            rows.push(`${date},"${s}"`);
          });
      });
    this.actionDownload({ filename: 'whatdid-comments.csv', contents: rows.join('\n') });
  }

  actionListView(v: ClientActions.ListLiteral): void
  {
    if (v !== this.props.listView)
    {
      this.props.listView = v;
      this.forceRender();
    }
  }

  actionExportStats(): void
  {
    let rows: string[] = [ 'Date,Category,Event' ];
    Object.values(this.env.ss.yearCache).forEach((y: CT.YearRecord) => {
        if (y.days) Object.keys(y.days).forEach((dayKey: string) => {
            let ix = y.days[dayKey];
            let dayMonth = dayKey.split('.');
            let date = `${Number(dayMonth[1])+1}/${dayMonth[0]}/${y.year}`;
            Object.values(ix).forEach((i: CT.Instance) => {
                if (i.on)
                {
                  let e = this.env.ss.events[i.eventid];
                  if (e)
                  {
                    let c = this.env.ss.categories[e.idCat];
                    if (c)
                      rows.push(`${date},"${c.name}","${e.name}"`);
                  }
                }
              });
          });
      });
    this.actionDownload({ filename: 'whatdid-events.csv', contents: rows.join('\n') });
  }

  actionDownload(param: { filename: string, contents: string }): void
  {
    let href = 'data:text/plain;charset=utf-8,' + encodeURIComponent(param.contents);

    // adapted from https://ourcodeworld.com/articles/read/189/
    //   how-to-create-a-file-and-generate-a-download-with-javascript-in-the-browser-without-a-server
    let element = document.createElement('a');
    element.setAttribute('href', href);
    element.setAttribute('download', param.filename);
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  }

  actionEvents(): void
  {
    this.pushViewState('events');
  }

  actionEvent(idEvent?: string): void
  {
    this.pushViewState('event');
    if (this.env.ss.events[idEvent])
      this.props.eventEdit = Util.deepCopy(this.env.ss.events[idEvent]);
    else
      this.props.eventEdit = { id: '', idCat: '', name: '', description: '', color: this.env.ss.nextColor() };
  }

  actionToggleReadOnly(): void
  {
    this.props.readonly = !this.props.readonly;
    this.forceRender();
  }

  actionDeleteEvent(idEvent: string, force: boolean = false): void
  {
    let e = this.env.ss.events[idEvent];
    if (!e) return;
    e = Util.deepCopy(e);
    e.deleted = true;
    let stats = this.env.ss.stats();
    let n = stats.get(idEvent);
    if (n && !force)
      this.props.actions.fire(ClientActions.Alert, { message: `That event has been used ${n} times. Are you sure you want to delete it? Deleting the event will delete all instances of the event in your daily record.`, ok: 'Delete', cancel: 'Cancel',
        onClose: (ok: boolean) => { if (ok) this.env.ss.updateEvent(e) }
        });
    else
      this.env.ss.updateEvent(e);
  }

  actionUpdateComment(comment: string): void
  {
    const {dateString} = this.props;

    let year = this.env.ss.toYearKey(dateString);
    let dayKey = this.env.ss.toDayKey(dateString);
    this.env.ss.updateComments(year, { [dayKey]: comment });
  }

  actionToggleInstance(eid: string): void
  {
    const {dateString} = this.props;

    let dayKey = this.env.ss.toDayKey(dateString);
    let year = this.env.ss.toYearKey(dateString);
    let ix = this.env.ss.instancesByDate(dateString);
    let oldi = ix[eid] || { eventid: eid, on: false };
    ix[eid] = { eventid: eid, on: !oldi.on };
    this.env.ss.updateDays(year, { [dayKey]: ix });

  }

  actionCategory(idCategory?: string): void
  {
    this.pushViewState('category');
    if (this.env.ss.categories[idCategory])
      this.props.categoryEdit = Util.deepCopy(this.env.ss.categories[idCategory]);
    else
      this.props.categoryEdit = { id: Util.createGuid(), name: '', description: '' };
  }

  actionColors(): void
  {
    this.pushViewState('colors');
  }

  actionIncrDate(incr: number): void
  {
    let {dateString, viewState} = this.props;

    switch (viewState.top)
    {
      case 'day':   dateString = DU.addDays(dateString, incr); break;
      case 'month': dateString = DU.addMonths(dateString, incr); break;
      case 'year':  dateString = DU.addYears(dateString, incr); break;
      case 'stats':  dateString = DU.addYears(dateString, incr); break;
    }
    this.actionSetDate(dateString);
    this.forceRender();
  }

  actionNextDate(): void
  {
    this.actionIncrDate(1);
  }

  actionPrevDate(): void
  {
    this.actionIncrDate(-1);
  }
}

let theApp: App = null;


function StartupApp()
{
  theApp = new App();
  theApp.initialize();
}


$(StartupApp);
