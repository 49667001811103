// Core react imports
import * as React from 'react';

// Mui Imports
import * as Mui from './wrapmui';
import * as Icons from './wrapicons';

// Public utilities
import classNames = require('classnames');

// Shared libraries
import { Util } from '@dra2020/baseclient';

// App libraries
import * as CT from '../../shared/coretypes';
import * as SD from '../../shared/simpledate';
import { Environment } from '../env';
import * as ClientActions from '../clientactions';
import * as SS from '../serverstate';
import * as DU from '../dateutil';
import * as MA from './materialapp';

export interface CategoryViewProps
{
  actions: ClientActions.ClientActions,
  textInit: ClientActions.TextInit,
  categoryEdit: CT.Category,

  classes?: any,
  theme?: any,
}

export interface CategoryViewState
{
  textInit: ClientActions.TextInit,
  categories: string[],
  matches: string[],
  showMatches: boolean,
}

function CategoryViewStyles(theme: any): any
{
  return (MA.AppStyles(theme));
}

class InternalCategoryView extends React.Component<CategoryViewProps, CategoryViewState>
{
  constructor(props: any)
  {
    super(props);

    this.textChange = this.textChange.bind(this);
    this.categoriesChange = this.categoriesChange.bind(this);
    this.handleDone = this.handleDone.bind(this);
    this.handleCancel = this.handleCancel.bind(this);

    let categories = props.actions.env.ss.categoryNames();
    let matches = props.textInit.category ? [ props.textInit.category ] : categories;
    this.state = {
        textInit: Util.shallowCopy(props.textInit),
        categories: categories,
        matches: matches,
        showMatches: false,
      };
  }

  textChange(e: any): void
  {
    let {textInit} = this.state;
    textInit[e.target.name] = e.target.value;
    this.setState({ textInit: textInit });
  }

  categoriesChange(e: any, newvalue: string): void
  {
    const {textInit} = this.state;

    textInit['category'] = newvalue;
    this.setState({ textInit: textInit });
  }

  renderTextFields(): any
  {
    const {classes, actions, categoryEdit} = this.props;
    let {textInit, categories} = this.state;

    return (
        <div>
          <div className={classes.spreadRow}>
            <div className={classes.spreadRow} style={{ minWidth: '150px', flexGrow: 1 }}>
              <Mui.TextField
                className={classes.mainTextField}
                required
                autoFocus
                margin='dense'
                id='name'
                label='Name'
                inputProps={{name: 'name'}}
                onChange={this.textChange}
                type='text'
                value={textInit.name}
                variant='standard'
              />
            </div>
          </div>
          <div className={classes.spreadRow} style={{ minWidth: '150px', flexGrow: 1 }}>
            <Mui.TextField
              className={classes.mainTextField}
              required
              margin='dense'
              id='description'
              label='Description'
              inputProps={{name: 'description'}}
              onChange={this.textChange}
              type='text'
              value={textInit.description}
              variant='standard'
            />
          </div>
        </div>
      );
  }

  handleDone(): void
  {
    const {actions, categoryEdit} = this.props;
    const {textInit} = this.state;

    let name = textInit.name.trim();
    let category = actions.env.ss.categoryByName(name);
    if (! name)
      actions.fire(ClientActions.Alert, { message: 'Please provide a name for your category!' });
    else if (category && category.id !== categoryEdit.id)
      actions.fire(ClientActions.Alert, { message: 'That category name is already defined!' });
    else
    {
      categoryEdit.name = name;
      categoryEdit.description = textInit.description.trim();
      actions.env.ss.updateCategory(categoryEdit);
      actions.fire(ClientActions.PopView);
    }
  }

  handleCancel(): void
  {
    const {actions} = this.props;

    actions.fire(ClientActions.PopView);
  }

  renderDone(): any
  {
    const {classes, actions} = this.props;

    return (
        <div className={classes.buttonRow}>
          <div className={classes.growAble}></div>

          <Mui.Button variant='outlined' onClick={(e: any) => { this.handleCancel() }}>
            Cancel
          </Mui.Button>
          &nbsp;
          &nbsp;
          <Mui.Button variant='outlined' onClick={(e: any) => { this.handleDone() }} color='primary'>
            Save
          </Mui.Button>
        </div>
      );
  }

  render(): any
  {
    const {classes, actions} = this.props;
    const {textInit} = this.state;

    return (
      <div  id={'categoryview'} className={classes.mainCanvasWrapper}>
        <div className={classes.mainCanvasInner}>
          <div className={classNames(classes.fontMedium)}>
            <Mui.Tooltip title={MA.getTooltip('Return to event list')}>
              <Mui.IconButton
                color='inherit'
                aria-label='back'
                onClick={() => actions.fire(ClientActions.PopView)}
                size='small'
               >
                <Icons.Clear />
               </Mui.IconButton>
            </Mui.Tooltip>
          </div>
          {this.renderTextFields()}
          {this.renderDone()}
        </div>
      </div>
    );
  }
}

let StyledCategoryView: any = Mui.withStyles(CategoryViewStyles, {withTheme: true})(InternalCategoryView);
export const CategoryView: new () => React.Component<CategoryViewProps, {}> = StyledCategoryView;
