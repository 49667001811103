import * as SD from './simpledate';

export interface User
{
  id?: string,
  name?: string,
}

// Defintion of an event
export interface Event
{
  id: string,
  idCat: string,
  category?: string,  // temporary, before idCat created
  name: string,
  description: string,
  color: string,
  deleted?: boolean,
}

// A single instance of an event
export interface Instance
{
  eventid: string,
  on: boolean,
  comment?: string,
}

// Definition of a category of events
export interface Category
{
  id: string,
  name: string,
  description: string,
}

export type EventIndex = { [id: string]: Event };
export type CategoryIndex = { [id: string]: Category };
export type InstanceIndex = { [eventid: string]: Instance };
export type DayIndex = { [day: string]: InstanceIndex };
export type CommentIndex = { [day: string]: string };
export type EventsByCategory = Map<string, string[]>;

// List of user's events
export interface EventRecord
{
  id?: string,
  createdBy?: string,
  createTime?: string,
  modifyTime?: string,
  isdeleted?: boolean,
  expunged?: boolean,
  events?: EventIndex,
  _atomicUpdate?: number,
}

// List of user's categories
export interface CategoryRecord
{
  id?: string,
  createdBy?: string,
  createTime?: string,
  modifyTime?: string,
  isdeleted?: boolean,
  expunged?: boolean,
  categories?: CategoryIndex,
  _atomicUpdate?: number,
}

export interface YearRecord
{
  id?: string,
  createdBy?: string,
  createTime?: string,
  modifyTime?: string,
  isdeleted?: boolean,
  expunged?: boolean,
  year: string,
  days?: DayIndex,
  _atomicUpdate?: number,
}

export interface CommentRecord
{
  id?: string,
  createdBy?: string,
  createTime?: string,
  modifyTime?: string,
  isdeleted?: boolean,
  expunged?: boolean,
  year: string,
  days?: CommentIndex,
  _atomicUpdate?: number,
}

export type EventCache = { [id: string]: EventRecord };
export type CategoryCache = { [id: string]: CategoryRecord }
export type YearCache = { [id: string]: YearRecord }
export type CommentCache = { [id: string]: CommentRecord }

export function parseEmail(s: string): { full: string, name: string, domain: string }
{
  if (!s) return null;
  let a = s.split('@');
  if (a.length != 2) return null;
  let name = a[0].trim().toLowerCase();
  let domain = a[1].trim().toLowerCase();
  if (name.length == 0 || domain.length == 0) return null;
  a = domain.split('.');
  if (a.length < 2) return null;
  if (a[a.length-1].length == 0) return null;
  return { full: `${name}@${domain}`, name, domain };
}
