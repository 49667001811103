// Public libraries
// Libraries
import { Util, FSM } from "@dra2020/baseclient";

// App
import { Environment } from "./env";

export interface FsmAjaxArgs
{
  method?: string,
  url?: string,
  data?: any,
  contentType?: any,
  dataType?: string,
  headers?: any,
  crossDomain?: any,
}

const DefaultAjaxArgs: FsmAjaxArgs =
{
  method: 'POST',
  contentType: 'application/json; charset=utf-8',
  dataType: 'json',
  headers: null,
};

export class FsmAjax extends FSM.Fsm
{
  // input
  args: FsmAjaxArgs;

  // these fields available on completion
  data: any;
  response: any;
  statusMessage: string;
  errorMessage: string;
  _resultCode: number;
  _statusCode: number;
  _length: number;
  controller: AbortController;

  constructor(env: Environment, args: FsmAjaxArgs)
  {
    super(env);
    this.args = Util.shallowAssignImmutable(DefaultAjaxArgs, args);
    if (this.args.data != null && typeof this.args.data !== 'string' && this.args.dataType !== 'binary')
      this.args.data = JSON.stringify(this.args.data);
    this.setComplete = this.setComplete.bind(this);
    this.setError = this.setError.bind(this);
    //console.log(`API call: ${this.args.url} ${this.args.data}`);
  }

  get env(): Environment { return this._env as Environment }

  purge(): void
  {
    delete this.response;
    delete this.data;
  }

  setComplete(data: any): void
  {
    //console.log(`API return: ${this.args.url} ${JSON.stringify(data)}`);
    this.data = data;
    if (data && typeof data === 'object' && typeof data.result === 'number')
    {
      this._resultCode = data.result;
      this.env.ss.processResult(data);
    }
    this.setState(FSM.FSM_DONE);
  }

  setError(e: any): void
  {
    if (! this.done)
    {
      this.errorMessage = e.message;
      this.setState(FSM.FSM_ERROR);
    }
  }

  send(): void
  {
    this.controller = new AbortController();
    let params: any =
      {
        method: this.args.method,
        signal: this.controller.signal,
      };

    if (this.args.method === 'POST' || this.args.method === 'PUT')
      params.body = this.args.data;
    params.headers = this.args.headers || {};
    if (this.args.contentType)
      params.headers['Content-Type'] = this.args.contentType;
    if (params.method === 'GET' && this.args.url.indexOf('http') == 0)
    {
      delete params.headers;
      params.mode = 'cors';
    }

    fetch(this.args.url, params)
      .then((response: any) => {
          if (this.done) return;
          this.response = response;
          this._statusCode = response.status;
          if (response.headers && response.headers.get('Content-Length'))
            this._length = Number(response.headers.get('Content-Length'));
          if (response.status >= 200 && response.status < 300)
          {
            if (this.args.dataType === 'binary')
            {
              response.arrayBuffer()
                .then(this.setComplete)
                .catch(this.setError);
            }
            else if (this.args.dataType === 'json')
            {
              response.json()
                .then(this.setComplete)
                .catch(this.setError);
            }
            else
            {
              response.text()
                .then(this.setComplete)
                .catch(this.setError);
            }
          }
          else  // treat non-2XX return result as error (e.g. 404)
          {
            this.errorMessage = `Non-success status return ${this.statusCode}`;
            this.setState(FSM.FSM_ERROR);
          }
        })
      .catch(this.setError);
  }

  get resultCode(): number { return this._resultCode }
  get statusCode(): number { return this._statusCode }
  get noexist(): boolean { return this.statusCode == 404 }
  get length(): number { return this._length }

  cancel(): void
  {
    if (! this.done)
    {
      if (this.controller && ! this.response)
        this.controller.abort();
      this.setState(FSM.FSM_ERROR);
    }
  }

  // Canonical tick() function - can be overridden, just remember to start by calling send()
  // But prefer to compose FSMs rather than override class.
  tick(): void
  {
    if (this.ready && this.isDependentError)
      this.setState(FSM.FSM_ERROR);
    else if (this.ready)
    {
      switch (this.state)
      {
        case FSM.FSM_STARTING:
          this.send();
          this.setState(FSM.FSM_PENDING);
          break;

        case FSM.FSM_PENDING:
          // no-op - completion happens in ajax callbacks
          break;
      }
    }
  }
}

export interface PresignParams
{
  contentType?: string;
}

export class FsmPresignUrl extends FsmAjax
{
  constructor(env: Environment, params?: PresignParams)
  {
    let args: FsmAjaxArgs = { url: '/api/sessions/presign' };
    args.data = { contentType: params && params.contentType ? params.contentType : 'text/plain; charset=UTF-8' };

    super(env, args);
  }

  get url(): any { return this.resultCode !== 0 ? null : this.data.presign.url; }
  get key(): string { return this.resultCode !== 0 ? null : this.data.presign.key; }
}

function toUrl(resource: string, bucket?: string): string
{
  // TODO: plumb bucket path
  return resource;
}

export class FsmPutJson extends FsmAjax
{
  constructor(env: Environment, url: any, data: string)
  {
    super(env, { url: url, method: 'PUT', data: data, contentType: 'application/json; charset=UTF-8', dataType: 'json' });
  }
}

export class FsmPutText extends FsmAjax
{
  constructor(env: Environment, url: any, data: string)
  {
    super(env, { url: url, method: 'PUT', data: data, contentType: 'text/plain; charset=UTF-8', dataType: 'text' });
  }
}

export class FsmPutBinary extends FsmAjax
{
  constructor(env: Environment, url: any, data: ArrayBuffer)
  {
    super(env, { url: url, method: 'PUT', data: data, contentType: 'application/octet-stream', dataType: 'binary' });
  }
}

export class FsmGetText extends FsmAjax
{
  constructor(env: Environment, url: any)
  {
    super(env, { url: url, method: 'GET', data: '', contentType: null, dataType: 'text' });
  }
}

export class FsmGetJSON extends FsmAjax
{
  constructor(env: Environment, url: any)
  {
    super(env, { url: url, method: 'GET', data: '{}', contentType: 'application/json; charset=UTF-8', dataType: 'json' });
  }
}

export class FsmGetJSONData extends FsmGetJSON
{
  constructor(env: Environment, resource: string, bucket?: string)
  {
    super(env, toUrl(resource, bucket));
  }
}

export class FsmGetBinaryData extends FsmAjax
{
  constructor(env: Environment, resource: string, bucket?: string)
  {
    super(env, { url: toUrl(resource, bucket), method: 'GET', data: '{}', contentType: 'application/octet-stream', dataType: 'binary' });
  }
}
