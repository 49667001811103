// Core react imports
import * as React from 'react';

// Mui Imports
import * as Mui from './wrapmui';
import * as Icons from './wrapicons';

// Public utilities
import classNames = require('classnames');

// Shared libraries
import { Util } from '@dra2020/baseclient';

// App libraries
import * as ClientActions from "../clientactions";
import * as MA from './materialapp';

export interface ProfileMenuProps
{
  actions: ClientActions.IClientActions,
  elOn: any,

  classes?: any,
  theme?: any,
}

export interface ProfileMenuState
{
  elOn: any,
}

function ProfileMenuStyles(theme: any): any
{
  return (MA.AppStyles(theme));
}

class InternalProfileMenu extends React.Component<ProfileMenuProps, ProfileMenuState>
{
  constructor(props: any)
  {
    super(props);
    this.state = { elOn: props.elOn };
  }

  handleClose(): void
  {
    const {actions} = this.props;

    actions.fire(ClientActions.ProfileMenu);
    this.setState({ elOn: null });
  }

  render(): any
  {
    const {classes, actions} = this.props;
    const {env} = actions;
    const {elOn} = this.state;

    let u = env.ss.user;
    let isAnon = env.ss.isAnon;

    let act = (a: number, arg?: any) => { this.handleClose(); actions.fire(a, arg) }

    return (
      <Mui.Menu
        open={true}
        variant={'menu'}
        onClose={() => this.handleClose()}
        anchorEl={elOn}
        anchorOrigin={{vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{vertical: 'top', horizontal: 'center' }}
      >
        {isAnon
         ? null
         : <Mui.MenuItem>
             {u.name}
           </Mui.MenuItem>
        }
        {isAnon
         ? null
         : <Mui.MenuItem onClick={() => { act(ClientActions.Open, { dialogname: 'profile', textInit: u }) }}>
             <Icons.AccountCircle />
             <span className={classes.menuText}>Profile</span>
           </Mui.MenuItem>
        }
        {isAnon
        ? null
        : <Mui.MenuItem onClick={() => { act(ClientActions.Logout) }}>
            <span className="iconify" data-icon="bx:bx-log-out" data-inline="false" data-width="1.5em" data-height="1.5em"></span>
            <span className={classes.menuText}>Log Out</span>
          </Mui.MenuItem>
        }
        {!isAnon
        ? null
        : <Mui.MenuItem onClick={(e: any) => act(ClientActions.Open, { dialogname: 'login', textInit: { signup: false } })}>
            <span className="iconify" data-icon="bx:bx-log-in" data-inline="false" data-width="1.5em" data-height="1.5em"></span>
            <span className={classes.menuText}>Log In</span>
          </Mui.MenuItem>
        }
        {!isAnon
        ? null
        : <Mui.MenuItem onClick={(e: any) => act(ClientActions.Open, { dialogname: 'login', textInit: { signup: true } })}>
            <span className="iconify" data-icon="bx:bx-log-in-circle" data-inline="false" data-width="1.5em" data-height="1.5em"></span>
            <span className={classes.menuText}>Sign Up</span>
          </Mui.MenuItem>
        }
      </Mui.Menu>
      );

  }
}

let StyledProfileMenu: any = Mui.withStyles(ProfileMenuStyles, {withTheme: true})(InternalProfileMenu);
export const ProfileMenu: new () => React.Component<ProfileMenuProps, {}> = StyledProfileMenu;
