// Core react imports
import * as React from 'react';

// Mui Imports
import * as Mui from './wrapmui';
import * as Icons from './wrapicons';

// Public utilities
import classNames = require('classnames');

// Shared libraries
import { Util } from '@dra2020/baseclient';

// App libraries
import * as CT from '../../shared/coretypes';
import * as SD from '../../shared/simpledate';
import { Environment } from '../env';
import * as ClientActions from '../clientactions';
import * as SS from '../serverstate';
import * as DU from '../dateutil';
import * as MA from './materialapp';

function hasDups(a: string[]): boolean
{
  let set = new Set<string>(a);
  return a.length != set.size;
}

export interface EventViewProps
{
  actions: ClientActions.ClientActions,
  textInit: ClientActions.TextInit,
  eventEdit: CT.Event,

  classes?: any,
  theme?: any,
}

export interface EventViewState
{
  textInit: ClientActions.TextInit,
  categories: string[],
}

function EventViewStyles(theme: any): any
{
  return (MA.AppStyles(theme));
}

class InternalEventView extends React.Component<EventViewProps, EventViewState>
{
  constructor(props: any)
  {
    super(props);

    this.textChange = this.textChange.bind(this);
    this.categoriesChange = this.categoriesChange.bind(this);
    this.handleDone = this.handleDone.bind(this);
    this.handleCancel = this.handleCancel.bind(this);

    let categories = props.actions.env.ss.categoryNames();
    this.state = {
        textInit: Util.shallowCopy(props.textInit),
        categories: categories,
      };
  }

  textChange(e: any): void
  {
    let {textInit} = this.state;
    textInit[e.target.name] = e.target.value;
    this.setState({ textInit: textInit });
  }

  categoriesChange(e: any, newvalue: string): void
  {
    const {textInit} = this.state;

    textInit['category'] = newvalue;
    this.setState({ textInit: textInit });
  }

  renderTextFields(): any
  {
    const {classes, actions, eventEdit} = this.props;
    let {textInit, categories} = this.state;

    return (
        <div>
          <div className={classes.spreadRow}>
            <div className={classes.spreadRow} style={{ minWidth: '150px', marginLeft: '4px', marginTop: '8px', marginBottom: '4px', flexGrow: 1 }}>
              <Mui.Autocomplete
                id='category'
                freeSolo={true}
                openOnFocus
                disableClearable
                options={categories}
                onInputChange={this.categoriesChange}
                onChange={this.categoriesChange}
                value={textInit.category}
                inputValue={textInit.category}
                renderInput={(params) => <Mui.TextField {...params} variant='standard' className={classes.mainTextField} label='Category' />}
                sx={{ flexGrow: 1 }}
              />
            </div>
          </div>
          <div className={classes.spreadRow}>
            <div className={classes.spreadRow} style={{ minWidth: '150px', flexGrow: 1 }}>
              <Mui.TextField
                className={classes.mainTextField}
                required
                autoFocus
                margin='dense'
                id='name'
                label='Name'
                inputProps={{name: 'name'}}
                onChange={this.textChange}
                type='text'
                value={textInit.name}
                variant='standard'
              />
            </div>
          </div>
          <div className={classes.spreadRow} style={{ minWidth: '150px', flexGrow: 1 }}>
            <Mui.TextField
              className={classes.mainTextField}
              required
              margin='dense'
              id='description'
              label='Description'
              inputProps={{name: 'description'}}
              onChange={this.textChange}
              type='text'
              value={textInit.description}
              variant='standard'
            />
          </div>
          <div className={classes.simpleRow} style={{paddingTop: '4px'}}>
            <Mui.Typography style={{paddingRight: '4px'}}>Color:</Mui.Typography>
            <Mui.ToggleButton className={classes.eventButton} onChange={(e: any) => { this.handleColor() }}
              style={{color: eventEdit.color, borderColor: eventEdit.color}}
              selected={false}
              size={'small'}
              value={eventEdit.color}>
              {eventEdit.color}
            </Mui.ToggleButton>
          </div>
        </div>
      );
  }

  handleColor(): void
  {
    const {actions, eventEdit} = this.props;
    const {textInit} = this.state;

    // Copy textInit fields into eventEdit since we will lose state on pushing color view
    eventEdit.category = textInit.category; // this is just stored temporarily here, see renderEvent in material.tsx
    eventEdit.name = textInit.name;
    eventEdit.description = textInit.description;

    actions.fire(ClientActions.Colors);
  }

  handleDone(): void
  {
    const {actions, eventEdit} = this.props;
    const {textInit} = this.state;
    let names: string[];

    if (eventEdit.id)
      names = [ textInit.name.trim() ];
    else
      names = textInit.name.split(',').map(s => s.trim()).filter(s => !!s);

    let good = false;
    let category = textInit.category.trim();
    if (names.length == 0 || !names[0])
      actions.fire(ClientActions.Alert, { message: 'Please provide a name for your event!' });
    else if (hasDups(names))
      actions.fire(ClientActions.Alert, { message: 'Each event must have a unique name!' });
    else if (! category)
      actions.fire(ClientActions.Alert, { message: 'Please provide a category for your event!' });
    else
    {
      let events: CT.Event[] = names.map(s => actions.env.ss.eventByName(s)).filter((e: CT.Event) => e != null);
      if (events.length > 1 || (events.length == 1 && events[0].id !== eventEdit.id))
      {
        let s = events.map(e => e.name).join(', ');
        if (events.length > 1)
          actions.fire(ClientActions.Alert, { message: `These event names (${s}) are already defined!` });
        else
          actions.fire(ClientActions.Alert, { message: `The event name ${s} is already defined!` });
      }
      else
        good = true;
    }

    if (good)
    {
      let c = actions.env.ss.categoryByName(category);
      if (! c)
      {
        c = { id: Util.createGuid(), name: category, description: '' };
        actions.env.ss.updateCategory(c);
      }
      names.forEach(name => {
          let e: CT.Event = {
              id: Util.createGuid(),
              name,
              description: textInit.description.trim(),
              idCat: c.id,
              color: eventEdit.color,
            };
          if (eventEdit.id)
            e.id = eventEdit.id;
          actions.env.ss.updateEvent(e);
        });
        
      actions.fire(ClientActions.PopView);
    }
  }

  handleCancel(): void
  {
    const {actions} = this.props;

    actions.fire(ClientActions.PopView);
  }

  handleDelete(): void
  {
    const {actions, eventEdit} = this.props;

    actions.fire(ClientActions.DeleteEvent, eventEdit.id);
    actions.fire(ClientActions.PopView);
  }

  renderDone(): any
  {
    const {classes, actions, eventEdit} = this.props;

    return (
        <div className={classes.buttonRow}>
          {eventEdit.id
           ? <Mui.Button variant='outlined' onClick={(e: any) => { this.handleDelete() }}>
              Delete
             </Mui.Button>
           : null}
          <div className={classes.growAble}></div>

          <Mui.Button variant='outlined' onClick={(e: any) => { this.handleCancel() }}>
            Cancel
          </Mui.Button>
          &nbsp;
          &nbsp;
          <Mui.Button variant='outlined' onClick={(e: any) => { this.handleDone() }} color='primary'>
            Save
          </Mui.Button>
        </div>
      );
  }

  renderMessage(): any
  {
    const {classes, eventEdit} = this.props;

    if (eventEdit.id) return null;

    return ( <Mui.Typography>
              Create your new events and categories. You can create multiple events at one time by separating each new event name with a comma.
             </Mui.Typography> )
  }

  render(): any
  {
    const {classes, actions} = this.props;
    const {textInit} = this.state;

    return (
      <div  id={'eventview'} className={classes.mainCanvasWrapper}>
        <div className={classes.mainCanvasInner}>
          <div className={classNames(classes.fontMedium)}>
            <Mui.Tooltip title={MA.getTooltip('Return to event list')}>
              <Mui.IconButton
                color='inherit'
                aria-label='back'
                onClick={() => actions.fire(ClientActions.PopView)}
                size='small'
               >
                <Icons.Clear />
               </Mui.IconButton>
            </Mui.Tooltip>
          </div>
          {this.renderMessage()}
          {this.renderTextFields()}
          {this.renderDone()}
        </div>
      </div>
    );
  }
}

let StyledEventView: any = Mui.withStyles(EventViewStyles, {withTheme: true})(InternalEventView);
export const EventView: new () => React.Component<EventViewProps, {}> = StyledEventView;
