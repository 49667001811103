// Core react imports
import * as React from 'react';

// Mui Imports
import * as Mui from './wrapmui';

// Public utilities
import classNames = require('classnames');

// App libraries
import * as ClientActions from "../clientactions";
import * as MA from './materialapp';

export interface ProgressViewProps
{
  actions: ClientActions.ClientActions;
  progressState: ClientActions.ParamProgress;

  classes?: any;
  theme?: any;
}

function ProgressViewStyles(theme: any): any
{
  return (MA.AppStyles(theme));
}

class InternalProgressView extends React.Component<ProgressViewProps, {}>
{
  constructor(props: any)
  {
    super(props);

    this.handleClose = this.handleClose.bind(this);
  }

  handleClose(e: any): void
  {
    const {actions, progressState} = this.props;

    if (progressState.onClose)
      progressState.onClose(true);

    actions.fire(ClientActions.Close, { dialogname: 'progress' });
  }

  render(): any
  {
    const {classes, actions, progressState} = this.props;

    let icon = progressState.value === undefined
                  ? <Mui.CircularProgress />
                  : <Mui.CircularProgress variant='determinate' value={progressState.value} />;

    return (
      <Mui.Dialog
        open={true}
        onClose={this.handleClose}
        aria-labelledby="progress-dialog-title"
        aria-describedby="progress-dialog-description"
      >
        <Mui.DialogTitle id="progress-dialog-title">{progressState.title}</Mui.DialogTitle>
        <Mui.DialogContent>
          <div className={classes.dialogRoot} />
          <Mui.DialogContentText id="progress-dialog-description">
            {progressState.message}
          </Mui.DialogContentText>
          {icon}
        </Mui.DialogContent>
        <Mui.DialogActions>
          <Mui.Button onClick={this.handleClose} color="primary" autoFocus>
            Dismiss
          </Mui.Button>
        </Mui.DialogActions>
      </Mui.Dialog>
    );
  }
}

let StyledProgressView: any = Mui.withStyles(ProgressViewStyles, {withTheme: true})(InternalProgressView);
export const ProgressView: new () => React.Component<ProgressViewProps, {}> = StyledProgressView;
