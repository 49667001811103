// Core react imports
import * as React from 'react';

// Mui Imports
import * as Mui from './wrapmui';

// Public utilities
import classNames = require('classnames');

// App libraries
import * as ClientActions from "../clientactions";
import * as MA from './materialapp';


export interface AlertViewProps
{
  actions: ClientActions.ClientActions;
  alertState: ClientActions.ParamAlert;

  classes?: any;
  theme?: any;
}

function AlertViewStyles(theme: any): any
{
  return (MA.AppStyles(theme));
}

class InternalAlertView extends React.Component<AlertViewProps, {}>
{
  constructor(props: any)
  {
    super(props);

    this.handleClose = this.handleClose.bind(this);
  }

  multiline(message: string): any
  {
    let lines = message.split('\n');
    let divs = lines.map(line => { return (<div>{line}</div>) });
    return (<div>{divs}</div>);
  }

  handleClose(ok: boolean): void
  {
    const {actions, alertState} = this.props;

    if (alertState.onClose)
      alertState.onClose(ok);

    actions.fire(ClientActions.Close, { dialogname: 'alert' });
  }

  render(): any
  {
    const {classes, actions, alertState} = this.props;

    return (
      <Mui.Dialog
        open={true}
        onClose={() => this.handleClose(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Mui.DialogTitle id="alert-dialog-title">{alertState.title}</Mui.DialogTitle>
        <Mui.DialogContent>
          <div className={classes.dialogRoot} />
          <Mui.DialogContentText id="alert-dialog-description">
            {this.multiline(alertState.message)}
          </Mui.DialogContentText>
        </Mui.DialogContent>
        <Mui.DialogActions>
        { (alertState.ok && alertState.cancel)
          ? <div>
            <Mui.Button onClick={() => this.handleClose(true)} color="primary">
              {alertState.ok}
            </Mui.Button>
            <Mui.Button onClick={() => this.handleClose(false)} color="primary">
              {alertState.cancel}
            </Mui.Button>
            </div>
          : <Mui.Button onClick={() => this.handleClose(false)} color="primary">
              Dismiss
            </Mui.Button>
        }
        </Mui.DialogActions>
      </Mui.Dialog>
    );
  }
}

let StyledAlertView: any = Mui.withStyles(AlertViewStyles, {withTheme: true})(InternalAlertView);
export const AlertView: new () => React.Component<AlertViewProps, {}> = StyledAlertView;
