import { Environment } from './env';

export class EventFilter
{
  env: Environment;
  eFilter: Set<string>;
  all: boolean;

  constructor(env: Environment)
  {
    this.env = env;
    this.eFilter = new Set<string>();
    this.all = true;
  }

  reset(all: boolean = true): void
  {
    this.eFilter.clear();
    this.all = all;
  }

  isAll(): boolean
  {
    let ev = new EventFilter(this.env);
    ev.explicitAll();
    return this.isEqual(ev);
  }

  get size(): number
  {
    this.explicitAll();
    return this.eFilter.size;
  }

  forEach(cb: (eid: string) => void): void
  {
    this.explicitAll();
    this.eFilter.forEach(cb);
  }

  isEqual(ev: EventFilter): boolean
  {
    if (! ev.all)
    {
      let eq = true;
      ev.eFilter.forEach(eid => {
          if (! this.testEvent(eid))
            eq = false;
        });
      if (!eq) return false;
    }
    if (! this.all)
    {
      let eq = true;
      this.eFilter.forEach(eid => {
          if (! ev.testEvent(eid))
            eq = false;
        });
      if (!eq) return false;
    }
    return true;
  }

  explicitAll(): void
  {
    if (this.all)
    {
      this.env.ss.eventsByCategory.forEach(es => {
          es.forEach(eid => { this.setEvent(eid) })
        });
      this.all = false;
    }
  }

  setEvent(eid: string): void
  {
    this.eFilter.add(eid)
  }

  clearEvent(eid: string): void
  {
    this.explicitAll();
    this.eFilter.delete(eid)
  }

  setCategory(cid: string): void
  {
    this.env.ss.eventsByCategory.get(cid)?.forEach(eid => { this.setEvent(eid) })
  }

  clearCategory(cid: string): void
  {
    this.explicitAll();
    this.env.ss.eventsByCategory.get(cid)?.forEach(eid => { this.clearEvent(eid) })
  }

  testEvent(eid: string): boolean
  {
    return this.all || this.eFilter.has(eid)
  }

  testCategory(cid: string): boolean
  {
    if (this.all) return true;

    let on = true;

    this.env.ss.eventsByCategory.get(cid)?.forEach(eid => {
        if (! this.eFilter.has(eid))
          on = false;
      });
    return on;
  }

  testCategoryIndeterminate(cid: string): boolean
  {
    if (this.all) return false;

    let on: boolean;
    let ind = false;

    this.env.ss.eventsByCategory.get(cid)?.forEach(eid => {
        let thisOn = this.eFilter.has(eid);
        if (on === undefined)
          on = thisOn;
        else if (on !== thisOn)
          ind = true;
      });
    return ind;
  }
}
