// Core react imports
import * as React from 'react';

// Mui Imports
import * as Mui from './wrapmui';
import * as Icons from './wrapicons';

// Public utilities
import classNames = require('classnames');

// Shared libraries
import { Util } from '@dra2020/baseclient';

// App libraries
import * as CT from '../../shared/coretypes';
import * as SD from '../../shared/simpledate';
import { Environment } from '../env';
import * as ClientActions from '../clientactions';
import * as SS from '../serverstate';
import * as DU from '../dateutil';
import * as MA from './materialapp';

export interface DayViewProps
{
  actions: ClientActions.ClientActions,
  dateString: string,
  readonly: boolean,
  comment: string,

  classes?: any,
  theme?: any,
}

export interface DayViewState
{
}

function DayViewStyles(theme: any): any
{
  return (MA.AppStyles(theme));
}

class InternalDayView extends React.Component<DayViewProps, DayViewState>
{
  env: Environment;

  constructor(props: any)
  {
    super(props);

    this.env = props.actions.env;
    this.textChange = this.textChange.bind(this);
    this.state = { };
  }

  textChange(e: any): void
  {
    const {actions} = this.props;
    let comment = e.target.value;

    actions.fire(MA.SetCommentText, comment);
    actions.fire(ClientActions.UpdateComment, comment);
  }

  renderTextFields(): any
  {
    const {classes, actions, readonly, comment} = this.props;

    return (
        <div>
          <div className={classes.spreadRow} style={{ minWidth: '150px', flexGrow: 1 }}>
            <Mui.TextField
              className={classes.mainTextField}
              margin='dense'
              id='comment'
              label='Notes on the Day'
              inputProps={{name: 'comment', readOnly: readonly}}
              onChange={this.textChange}
              type='text'
              value={comment}
              variant='standard'
            />
          </div>
        </div>
      );
  }

  render(): any
  {
    const {classes, actions, readonly, dateString} = this.props;

    let ix = this.env.ss.instancesByDate(dateString);
    let dayKey = this.env.ss.toDayKey(dateString);
    let year = this.env.ss.toYearKey(dateString);
    let button = (e: CT.Event) => { return (
        <Mui.ToggleButton className={classes.eventButton} onChange={() => { if (!readonly) actions.fire(ClientActions.ToggleInstance, e.id) }}
          style={{color: e.color, borderColor: e.color}}
          sx={MA.sxToggle}
          selected={!!ix[e.id]?.on}
          size={'small'}
          value={e.id}>
            {e.name}
        </Mui.ToggleButton>)
      };

    let categories = Array.from(this.env.ss.eventsByCategory.keys()).sort(this.env.ss.sortCategory).map(idCat => {
        let events = this.env.ss.eventsByCategory.get(idCat).sort(this.env.ss.sortEvent).map((idEvent: string) => {
                                                    return button(this.env.ss.events[idEvent]) });
        return (
          <Mui.TableRow className={classNames(classes.categoryRow)}>
            <Mui.TableCell className={classes.categoryColumn}>
              {this.env.ss.categories[idCat]?.name || ''}
            </Mui.TableCell>
            <Mui.TableCell className={classes.eventColumn}>
              {events}
            </Mui.TableCell>
          </Mui.TableRow>)
      });

    return (
            <div className={classes.viewPort}>
              <Mui.Table style={{paddingTop: '10px', paddingBottom: '10px'}}>
                <Mui.TableBody>
                  {categories}
                </Mui.TableBody>
              </Mui.Table>
              {this.renderTextFields()}
            </div>
          )
  }
}

let StyledDayView: any = Mui.withStyles(DayViewStyles, {withTheme: true})(InternalDayView);
export const DayView: new () => React.Component<DayViewProps, {}> = StyledDayView;
